@import url('https://fonts.googleapis.com/css2?family=Archivo&family=Josefin+Sans:wght@700&family=Rubik&display=swap');

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px !important;
}

a {
    text-decoration: none !important;
}

:root {
    --josefin-text: 'Josefin Sans', sans-serif;
    --rubik-text: 'Rubik', sans-serif;
    --archivo-text: 'Archivo', sans-serif;
    --orange-color: #F6821F;
    --dark-blue-color: #005777;
    --white-color: #FFF;
}

.blog-part .blog-container {
    padding: 0px 150px;
}

.blog-part .blog-container .row .col-xl-12 {
    display: none;
}

.blog-part .title-part {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
}

.blog-part .title-part h6 {
    color: var(--orange-color);
    font-family: var(--rubik-text);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 15px;
    text-transform: uppercase;
}

.blog-part .title-part h1 {
    color: var(--dark-blue-color);
    text-align: center;
    font-family: var(--josefin-text);
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 65px;
    text-transform: uppercase;
}

.blog-part .view-blog-all {
    color: var(--orange-color);
    font-family: var(--rubik-text);
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.25px;
    text-decoration-line: underline !important;
    text-transform: uppercase;
    text-align: right;
    display: block;
}

.blog-part .view-blog-all .right-icon {
    margin-left: 18px;
}

.blog-part .blog-card {
    padding: 29px 21px;
    background-color: var(--white-color);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    margin-top: 100px;
    position: relative;
}

.blog-part .blog-card .blog-img img {
    border-radius: 5px;
}

.blog-part .blog-card .blog-date {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background: var(--white-color);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    bottom: 42%;
    left: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blog-part .blog-card .blog-date span:first-child {
    color: var(--orange-color);
    font-family: var(--rubik-text);
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 1px;
    text-transform: capitalize;
}

.blog-part .blog-card .blog-date span:last-child {
    color: var(--dark-blue-color);
    font-family: var(--rubik-text);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.3px;
}

.blog-part .blog-card .blog-content {
    margin-top: 63.86px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.blog-part .blog-card .blog-content h1 {
    color: var(--dark-blue-color);
    font-family: var(--josefin-text);
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.25px;
    text-transform: capitalize;
}

.blog-part .blog-card .blog-content p {
    color: #696969;
    font-family: var(--rubik-text);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.6px;
    text-transform: capitalize;
}

.blog-part .blog-card .know-more-btn {
    border-radius: 50px;
    background-color: var(--orange-color);
    width: 130px;
    height: 40px;
    border: none;
    margin-top: 40px;
    box-shadow: 0px 10px 30px rgba(0, 87, 119, 0.3);
}

.blog-part .blog-card .know-more-btn a {
    color: var(--white-color);
    font-family: var(--rubik-text);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 170%;
}

/* ---------------------------------medai queery Start----------------------- */
/* ---------------------------------mobile device Start --------------------- */
@media only screen and (min-width: 412px) and (max-width: 892px) {
    .blog-part .blog-container {
        margin: auto;
        padding: 0 20px;
    }

    .section-6 {
        padding: 50px 0px !important;
    }

    .blog-part {
        padding: 20px 0px 0px 0px !important;
    }

    .blog-part .title-part h6 {
        font-size: 15px;
        letter-spacing: 7.5px;
        text-align: center;
        margin: auto !important;
    }

    .blog-part .title-part h1 {
        font-size: 20px;
    }

    .blog-part .row .first:nth-child(3) .blog-card {
        margin-top: 20px;
    }

    .blog-part .view-blog-all {
        text-align: center;
        margin-top: 20px;
    }

    .blog-part .blog-card .blog-date {
        bottom: 57% !important;
    }
}

@media all and (min-width: 321px) and (max-width: 480px) {
    .blog-part .blog-container {
        margin: auto;
        padding: 0 20px;
    }

    .section-6 {
        padding: 50px 0px !important;
    }

    .blog-part {
        padding: 50px 0px 0px 0px !important;
    }

    .blog-part .title-part h6 {
        font-size: 15px;
        letter-spacing: 7.5px;
        text-align: center;
        margin: auto !important;
    }

    .blog-part .title-part h1 {
        font-size: 18px;
        text-align: center !important;
        width: 100%;
        line-height: 20px;
    }

    .blog-part .row>div:nth-child(3) {
        order: 1;
    }

    .blog-part .row .first:nth-child(4) .blog-card {
        margin-top: 20px;
    }

    .blog-part .view-blog-all {
        text-align: center;
        margin-top: 20px;
    }

    .blog-part .blog-card .blog-date {
        bottom: 59% !important;
    }

    .blog-part .title-part h6 {
        font-size: 15px;
        letter-spacing: 7.5px;
        text-align: center;
        margin: auto !important;
    }

    .blog-part .title-part h1 {
        font-size: 18px;
        text-align: center !important;
        width: 100%;
        line-height: 20px;
    }

    .blog-part .row .first:nth-child(3) .blog-card {
        margin-top: 20px;
    }

    .blog-part .view-blog-all {
        text-align: center;
        margin-top: 20px;
    }

    .blog-part .blog-card .blog-date {
        bottom: 62%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .blog-part .blog-container {
        padding: 0 40px;
    }

    .blog-part .title-part h6 {
        margin: 0px !important;
    }

    .blog-part .title-part h6 {
        font-size: 15px;
        letter-spacing: 7.5px;
    }

    .blog-part .title-part h1 {
        font-size: 20px;
        text-align: left;
        line-height: 20px;
    }

    .blog-part .view-blog-all {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.75px;
    }

    .blog-part .view-blog-all .right-icon {
        margin-left: 10px;
    }

    .blog-part .blog-card {
        margin-top: 50px;
        padding: 10px 9px 20px;
    }

    .blog-part .row .first:nth-child(3) .blog-card {
        margin-top: 50px;
    }

    .blog-part .blog-card .blog-content {
        margin-top: 40px;
    }

    .blog-part .blog-card .blog-content h1 {
        font-size: 14px;
        letter-spacing: 0.6px;
    }

    .blog-part .blog-card .blog-date {
        width: 50px;
        height: 50px;
        bottom: 62% !important;
        left: 15%;
        justify-content: center;
    }

    .blog-part .blog-card .blog-date span:first-child {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.32px;
    }

    .blog-part .blog-card .blog-date span:last-child {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.16px;
    }

    .blog-part .blog-card .blog-content p {
        font-size: 12px;
    }

    .blog-part .blog-card .know-more-btn {
        margin-top: 20px;
        padding: 9px 23px;
    }

    .blog-part .blog-card .know-more-btn a {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
    }
}

/* ---------------------------------mobile device End --------------------- */
@media only screen and (min-width: 1201px) and (max-width: 1440px) {
    .blog-part .title-part h6 {
        font-size: 26px;
    }

    .blog-part .title-part h1 {
        font-size: 46px;
    }

    .blog-part .blog-card {
        margin-top: 80px;
        padding: 22px 18px;
    }

    .blog-part .blog-card .blog-date {
        bottom: 57%;
        width: 55px;
        height: 55px;
        justify-content: center;
        left: 44px;
        
    }

    .blog-part .blog-card .blog-date span:first-child,
    .blog-part .blog-card .blog-date span:last-child {
        font-size: 16px;
    }

    .blog-part .blog-card .blog-content h1 {
        font-size: 23px;
    }

    .blog-part .view-blog-all {
        font-size: 22px;
    }

    .blog-part .view-blog-all .right-icon {
        margin-left: 12px;
    }
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
    .blog-part .title-part h6 {
        font-size: 30px;
    }
    .blog-part .blog-card .blog-date {
        border-top: 57%;
    }
    .blog-part .title-part h1 {
        font-size: 50px;
    }

    .blog-part .blog-card {
        margin-top: 80px;
        padding: 22px 18px;
    }

    .blog-part .blog-card .blog-date {
        bottom: 50%;
        width: 60px;
        height: 60px;
        justify-content: center;
        left: 50px;
    }

    .blog-part .blog-card .blog-date span:first-child,
    .blog-part .blog-card .blog-date span:last-child {
        font-size: 18px;
    }

    .blog-part .blog-card .blog-content h1 {
        font-size: 25px;
    }
}
@media only screen and (min-width: 1601px) and (max-width: 1880px) {
    .blog-part .title-part h6 {
        font-size: 30px;
    }

    .blog-part .title-part h1 {
        font-size: 50px;
    }

    .blog-part .blog-card {
        margin-top: 80px;
        padding: 22px 18px;
    }

    .blog-part .blog-card .blog-date {
        bottom: 42%;
        width: 60px;
        height: 60px;
        justify-content: center;
        left: 50px;
    }

    .blog-part .blog-card .blog-date span:first-child,
    .blog-part .blog-card .blog-date span:last-child {
        font-size: 18px;
    }

    .blog-part .blog-card .blog-content h1 {
        font-size: 25px;
    }
}

/* ---------------------------------medai queery End----------------------- */