@import url('https://fonts.googleapis.com/css2?family=Archivo&family=Josefin+Sans:wght@700&family=Rubik&display=swap');

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px !important;
}

a {
    text-decoration: none !important;
}

:root {
    --josefin-text: 'Josefin Sans', sans-serif;
    --rubik-text: 'Rubik', sans-serif;
    --archivo-text: 'Archivo', sans-serif;
    --orange-color: #F6821F;
    --dark-blue-color: #005777;
    --white-color: #FFF;
}

/* ---------------------------------------------------- Main Blog Body ---------------------------------------------------- */
.blog-part {
    height: auto;
    padding: 90px 0px 40px 0px;
}

.blog-part .blog-body:not(:last-child) {
    margin-bottom: 10px;
}

.blog-part .blog-body .blog-image {
    width: 1072px;
    height: 616px;
    position: relative;
}

.blog-part .blog-body .blog-image img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
}

.blog-part .blog-body .blog-content {
    border-radius: 5px;
    background: #F2F6F8;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.08);
    width: 860px;
    padding: 33px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin: auto;
    transform: translateY(-50%);
}

.reveal {
    position: relative;
    opacity: 0;
}

.reveal.active {
    opacity: 1;
}

.active.fade-left {
    animation: parthiv 1s ease-in;
}

@keyframes parthiv {
    from {
        opacity: 0;
        transform: scaleX(0);
    }

    to {
        opacity: 1;
        transform: scaleX(1);
    }
}


.blog-part .blog-body .blog-content p {
    color: var(--dark-blue-color);
    text-align: center;
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.blog-part .blog-body .blog-content h1 {
    color: var(--dark-blue-color);
    text-align: center;
    font-family: var(--josefin-text);
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
}

.blog-part .blog-body .blog-content a {
    color: var(--orange-color);
    text-align: center;
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
}

/* ---------------------------------------------------- Blog SideBar ---------------------------------------------------- */
.blog-search {
    width: 524px;
    height: 279px;
}

.blog-search,
.blog-categories {
    margin-bottom: 50px;
}

.blog-search .blog-search-top,
.blog-categories .blog-categories-top,
.trending-post .trending-post-top {
    background-color: var(--dark-blue-color);
    padding: 25px 35px;
    border-radius: 5px 5px 0px 0px;
}

.blog-search .blog-search-top h1,
.blog-categories .blog-categories-top h1,
.trending-post .trending-post-top h1 {
    color: var(--white-color);
    font-family: var(--josefin-text);
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
}

.blog-search .blog-search-bottom {
    border-radius: 0px 0px 5px 5px;
    background: #F2F6F8;
    padding: 67px 45px;
    display: flex;
}

.blog-search .blog-search-bottom .search-box {
    border-radius: 5px;
    border: none;
    outline: none;
    color: #696969;
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 21px 18px;
    width: 100%;
}

.blog-search .blog-search-bottom .search-icon-box {
    width: 69px;
    height: 65px;
    border-radius: 0px 5px 5px 0px;
    background-color: var(--dark-blue-color);
    display: grid;
    place-items: center;
    cursor: pointer;
}

.blog-categories .blog-categories-menus {
    padding: 52px 44px;
    border-radius: 0px 0px 5px 5px;
    background: #F2F6F8;
}

.blog-categories .blog-categories-menus ul {
    display: flex;
    gap: 45px;
    flex-direction: column;
    align-items: flex-start;
}

.blog-categories .blog-categories-menus ul li a {
    color: var(--dark-blue-color);
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.trending-post .trending-post-body {
    padding: 70px 32px;
    background-color: #F2F6F8;
}

.trending-post .trending-post-body .trending-post-content {
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

.trending-post .trending-post-body .trending-post-content:not(:last-child) {
    margin-bottom: 60px;
}

.trending-post .trending-post-body .trending-post-content .trending-post-img img {
    width: 117.131px;
    height: 117.131px;
    object-fit: cover;
}

.trending-post .trending-post-body .trending-post-content .trending-post-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.trending-post .trending-post-body .trending-post-content .trending-post-info h1 {
    color: #000;
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.trending-post .trending-post-body .trending-post-content .trending-post-info p {
    color: #696969;
    font-family: var(--rubik-text);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* ----------------------------------------------- Media Query ----------------------------------------------- */
/* ----------------one plus Start ---------------- */

@media only screen and (min-width: 412px) and (max-width: 892px) {
    /* .blog-part .blog-container .row {
        margin: auto;
    } */

    .blog-part .blog-body .blog-image {
        width: 100%;
        height: 100%;
    }

    .blog-part .blog-body .blog-content {
        width: 250px;
        padding: 10px 0px;
    }

    .blog-part .blog-body .blog-content p,
    .blog-part .blog-body .blog-content a {
        font-size: 12px;
    }

    .blog-part .blog-body .blog-content h1 {
        font-size: 15px;
        line-height: 0;
    }

    .blog-search {
        width: 100%;
        height: auto;
    }

    .blog-search .blog-search-bottom {
        padding: 48px 17px;
    }

    .blog-categories .blog-categories-menus {
        padding: 38px 15px;
        border-radius: 0px 0px 5px 5px;
        background: #F2F6F8;
    }

    .blog-categories .blog-categories-menus ul {
        gap: 28px
    }

    .trending-post .trending-post-body {
        padding: 35px 21px;
    }

    .trending-post .trending-post-body .trending-post-content .trending-post-img img {
        width: 60px;
        height: 60px;
    }

    .trending-post .trending-post-body .trending-post-content:not(:last-child) {
        margin-bottom: 50px;
    }

    .trending-post .trending-post-body .trending-post-content .trending-post-info h1,
    .trending-post .trending-post-body .trending-post-content .trending-post-info p {
        font-size: 12px;
    }

    .trending-post .trending-post-body .trending-post-content .trending-post-info {
        gap: 10px;
    }
}

/* ----------------one plus End ---------------- */
@media all and (min-width: 321px) and (max-width: 480px) {
    .blog-part .blog-container .row {
        margin: auto;
    }

    .blog-part .blog-body .blog-image {
        width: 100%;
        height: 100%;
    }

    .blog-part .blog-body .blog-content {
        width: 250px;
        padding: 10px 0px;
    }

    .blog-part .blog-body .blog-content p,
    .blog-part .blog-body .blog-content a {
        font-size: 12px;
    }

    .blog-part .blog-body .blog-content h1 {
        font-size: 15px;
        line-height: 0;
    }

    .blog-search {
        width: 100%;
        height: auto;
    }

    .blog-search .blog-search-bottom {
        padding: 48px 17px;
    }

    .blog-categories .blog-categories-menus {
        padding: 38px 15px;
        border-radius: 0px 0px 5px 5px;
        background: #F2F6F8;
    }

    .blog-categories .blog-categories-menus ul {
        gap: 28px
    }

    .trending-post .trending-post-body {
        padding: 35px 21px;
    }

    .trending-post .trending-post-body .trending-post-content .trending-post-img img {
        width: 60px;
        height: 60px;
    }

    .trending-post .trending-post-body .trending-post-content:not(:last-child) {
        margin-bottom: 50px;
    }

    .trending-post .trending-post-body .trending-post-content .trending-post-info h1,
    .trending-post .trending-post-body .trending-post-content .trending-post-info p {
        font-size: 12px;
    }

    .trending-post .trending-post-body .trending-post-content .trending-post-info {
        gap: 10px;
    }

}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    /* .blog-part .blog-container .row {
        margin: 0px;
        overflow: hidden;
    } */

    .blog-part .blog-body .blog-image {
        width: 100%;
        height: 100%;
    }

    .blog-part .blog-body .blog-content {
        width: 250px;
        padding: 10px 0px;
        gap: 10px;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
    }

    .blog-part .blog-body .blog-content p,
    .blog-part .blog-body .blog-content a {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 10px;
    }

    .blog-part .blog-body .blog-content h1 {
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .blog-search {
        width: 100%;
        height: auto;
    }

    .blog-search .blog-search-top,
    .blog-categories .blog-categories-top,
    .trending-post .trending-post-top {
        padding: 22px 17px 16px;
    }

    .blog-search .blog-search-top h1,
    .blog-categories .blog-categories-top h1,
    .trending-post .trending-post-top h1 {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .blog-search .blog-search-bottom {
        padding: 48px 17px;
    }

    .blog-search .blog-search-bottom .search-box {
        padding: 15px 14px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .blog-search .blog-search-bottom .search-icon-box {
        width: 60px;
        height: 49px;
    }

    .blog-search .blog-search-bottom .search-icon-box img {
        width: 20px;
        height: 20px;
    }

    .blog-categories .blog-categories-menus {
        padding: 38px 15px;
        border-radius: 0px 0px 5px 5px;
        background: #F2F6F8;
    }

    .blog-categories .blog-categories-menus ul {
        gap: 28px
    }

    .blog-categories .blog-categories-menus ul li a {
        font-size: 18px;
    }

    .trending-post .trending-post-body {
        padding: 28px 15px;
    }

    .trending-post .trending-post-body .trending-post-content {
        gap: 10px;
    }

    .trending-post .trending-post-body .trending-post-content:not(:last-child) {
        margin-bottom: 33px;
    }

    .trending-post .trending-post-body .trending-post-content .trending-post-img img {
        width: 65px;
        height: 65px;
    }

    .trending-post .trending-post-body .trending-post-content .trending-post-info {
        gap: 10px;
    }

    .trending-post .trending-post-body .trending-post-content .trending-post-info h1 {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .trending-post .trending-post-body .trending-post-content .trending-post-info p {
        color: #696969;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1440px) {

    /* ---------------------------------------------------- Section - 1 ---------------------------------------------------- */
    .blog-part {
        padding: 80px 0px 40px 0px;
    }

    .blog-part .blog-container {
        padding: 0px 100px;
    }

    .blog-part .blog-body .blog-image {
        width: 100%;
        height: 450px;
    }

    .blog-part .blog-body .blog-content {
        width: 530px;
        padding: 24px 0px;
        gap: 8px;
    }

    .blog-part .blog-body .blog-content p,
    .blog-part .blog-body .blog-content a {
        font-size: 18px;
    }

    .blog-part .blog-body .blog-content h1 {
        font-size: 28px;
    }

    /* ---------------------------------------------------- Blog SideBar ---------------------------------------------------- */
    .blog-search {
        width: 100%;
        height: auto;
    }

    .blog-search .blog-search-top,
    .blog-categories .blog-categories-top,
    .trending-post .trending-post-top {
        padding: 20px 26px;
    }

    .blog-search .blog-search-bottom,
    .blog-categories .blog-categories-menus,
    .trending-post .trending-post-body {
        padding: 50px 30px;
    }

    .blog-search .blog-search-bottom .search-box {
        padding: 20px 14px;
    }

    .blog-categories .blog-categories-menus ul {
        gap: 30px;
    }

    .trending-post .trending-post-body .trending-post-content .trending-post-img img {
        width: 95px;
        height: 95px;
    }

    .trending-post .trending-post-body .trending-post-content {
        gap: 16px;
        align-items: center;
    }

    .trending-post .trending-post-body .trending-post-content .trending-post-info h1 {
        font-size: 18px;
    }

    .trending-post .trending-post-body .trending-post-content .trending-post-info p {
        font-size: 14px;
    }

    .trending-post .trending-post-body .trending-post-content .trending-post-info {
        gap: 16px;
    }
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {

    /* ---------------------------------------------------- Section - 1 ---------------------------------------------------- */
    .blog-part .blog-container {
        padding: 0px 100px;
    }

    .blog-part .blog-body .blog-image {
        width: 100%;
        height: 520px;
    }

    .blog-part .blog-body .blog-content {
        width: 530px;
        padding: 24px 0px;
        gap: 8px;
    }

    .blog-part .blog-body .blog-content p,
    .blog-part .blog-body .blog-content a {
        font-size: 18px;
    }

    .blog-part .blog-body .blog-content h1 {
        font-size: 28px;
    }

    /* ---------------------------------------------------- Blog SideBar ---------------------------------------------------- */
    .blog-search {
        width: 100%;
        height: auto;
    }

    .blog-search .blog-search-top,
    .blog-categories .blog-categories-top,
    .trending-post .trending-post-top {
        padding: 20px 26px;
    }

    .blog-search .blog-search-bottom,
    .blog-categories .blog-categories-menus,
    .trending-post .trending-post-body {
        padding: 50px 30px;
    }

    .blog-search .blog-search-bottom .search-box {
        padding: 20px 14px;
    }

    .blog-categories .blog-categories-menus ul {
        gap: 30px;
    }

    .trending-post .trending-post-body .trending-post-content .trending-post-img img {
        width: 95px;
        height: 95px;
    }

    .trending-post .trending-post-body .trending-post-content {
        gap: 16px;
        align-items: center;
    }

    .trending-post .trending-post-body .trending-post-content .trending-post-info h1 {
        font-size: 18px;
    }

    .trending-post .trending-post-body .trending-post-content .trending-post-info p {
        font-size: 14px;
    }

    .trending-post .trending-post-body .trending-post-content .trending-post-info {
        gap: 16px;
    }
}
@media only screen and (min-width: 1600px) and (max-width: 1880px) {

    /* ---------------------------------------------------- Section - 1 ---------------------------------------------------- */
    .blog-part .blog-container {
        padding: 0px 100px;
    }

    .blog-part .blog-body .blog-image {
        width: 100%;
        height: 520px;
    }

    .blog-part .blog-body .blog-content {
        width: 530px;
        padding: 24px 0px;
        gap: 8px;
    }

    .blog-part .blog-body .blog-content p,
    .blog-part .blog-body .blog-content a {
        font-size: 18px;
    }

    .blog-part .blog-body .blog-content h1 {
        font-size: 28px;
    }

    /* ---------------------------------------------------- Blog SideBar ---------------------------------------------------- */
    .blog-search {
        width: 100%;
        height: auto;
    }

    .blog-search .blog-search-top,
    .blog-categories .blog-categories-top,
    .trending-post .trending-post-top {
        padding: 20px 26px;
    }

    .blog-search .blog-search-bottom,
    .blog-categories .blog-categories-menus,
    .trending-post .trending-post-body {
        padding: 50px 30px;
    }

    .blog-search .blog-search-bottom .search-box {
        padding: 20px 14px;
    }

    .blog-categories .blog-categories-menus ul {
        gap: 30px;
    }

    .trending-post .trending-post-body .trending-post-content .trending-post-img img {
        width: 95px;
        height: 95px;
    }

    .trending-post .trending-post-body .trending-post-content {
        gap: 16px;
        align-items: center;
    }

    .trending-post .trending-post-body .trending-post-content .trending-post-info h1 {
        font-size: 18px;
    }

    .trending-post .trending-post-body .trending-post-content .trending-post-info p {
        font-size: 14px;
    }

    .trending-post .trending-post-body .trending-post-content .trending-post-info {
        gap: 16px;
    }
}