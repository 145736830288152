:root {
    --josefin-text: 'Josefin Sans', sans-serif;
    --rubik-text: 'Rubik', sans-serif;
    --archivo-text: 'Archivo', sans-serif;
    --orange-color: #F6821F;
    --dark-blue-color: #005777;
    --white-color: #FFF;
}

.testimonial-part {
    margin-top: 100px;
    background-color: #F2F6F8;
}

.testimonial-part .testimonial-container {
    padding: 105px 150px;
}

.testimonial-part .testimonial-content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.testimonial-part .testimonial-content h6 {
    color: var(--orange-color);
    font-family: var(--rubik-text);
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 15px;
    text-transform: uppercase;
}

.testimonial-part .testimonial-content h1 {
    color: var(--dark-blue-color);
    text-align: center;
    font-family: var(--josefin-text);
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 65px;
    text-transform: uppercase;
}

.testimonial-part .testimonial-content p {
    color: #696969;
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%;
    letter-spacing: 1px;
    text-transform: capitalize;
    width: 661px;
}

.testimonial-part .slick-slide {
    width: 900px !important;
    margin: 14px 17.5px;
    position: relative;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
}

.testimonial-part .slick-slide::before {
    position: absolute;
    content: "";
    top: 33px;
    left: 30px;
    width: 98%;
    height: 95%;
    background-color: var(--orange-color);
    border-radius: 10px !important;
    z-index: -1;
}

.testimonial-part .slick-slide .testimonial-box {
    border-radius: 10px;
    background-color: var(--white-color);
    padding: 60px 29px;
    display: flex !important;
    align-items: flex-start;
    flex-direction: column;
    gap: 50px;
    width: 100px;
}

.testimonial-part .slick-slide .testimonial-box p {
    color: #696969;
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%;
    letter-spacing: 1px;
    text-transform: capitalize;
}

.testimonial-part .slick-slide .testimonial-box .client-details-box {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 30px;
}

.testimonial-part .slick-slide .testimonial-box .client-details-box img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.testimonial-part .slick-slide .testimonial-box .client-details-box h1 {
    color: #000;
    font-family: var(--rubik-text);
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 190%;
    letter-spacing: 1.25px;
    text-transform: capitalize;
    width: 100%;
}

.testimonial-part .slick-slide .testimonial-box .client-details-box h6 {
    color: #000;
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%;
    letter-spacing: 1px;
    text-transform: capitalize;
}

.testimonial-part .slick-slider .icon-left {
    position: absolute;
    left: -73%;
    top: 90%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid var(--orange-color);
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.testimonial-part .slick-slider .icon-right {
    position: absolute;
    right: 158%;
    top: 90%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid var(--orange-color);
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.testimonial-part .slick-slider .icon-left::before,
.testimonial-part .slick-slider .icon-right::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: var(--orange-color);
    transition: 0.2s ease-in-out;
}

.testimonial-part .slick-slider .icon-left:hover,
.testimonial-part .slick-slider .icon-right:hover {
    background-color: var(--orange-color);
}

.testimonial-part .slick-slider .icon-left:hover::before,
.testimonial-part .slick-slider .icon-right:hover::before {
    color: var(--white-color);
}

/* ---------------------------------medai queery Start----------------------- */
/* ---------------------------------mobile device Start --------------------- */
@media only screen and (min-width: 321px) and (max-width: 812px) {
    .testimonial-part {
        margin-top: 83px;
    }

    .testimonial-part .slick-slider .icon-left,
    .testimonial-part .slick-slider .icon-right {
        position: absolute;
        top: 120%;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 3px solid var(--orange-color);
        cursor: pointer;
        transition: 0.2s ease-in-out;
    }

    .testimonial-part .slick-slider .icon-left {
        left: 3%;
    }

    .testimonial-part .slick-slider .icon-right {
        right: 62%;
    }

    .testimonial-part .slick-slider .icon-left::before,
    .testimonial-part .slick-slider .icon-right::before {
        color: var(--orange-color);
    }

    .testimonial-part .testimonial-container {
        padding: 54px 20px 180px;
        overflow: hidden;
    }

    .testimonial-part .testimonial-content h6 {
        font-size: 15px;
        letter-spacing: 7.5px;
    }

    .testimonial-part .testimonial-content h1 {
        font-size: 20px;
        line-height: 20px;
    }

    .testimonial-part .testimonial-content p {
        font-size: 12px;
        letter-spacing: 0.6px;
        width: 100%;
    }

    .testimonial-part .slick-slide .testimonial-box {
        padding: 17px 13px;
    }

    .testimonial-part .slick-slide .testimonial-box .client-details-box img {
        width: 60px;
        height: 60px;
    }

    .testimonial-part .slick-slide .testimonial-box .client-details-box h1 {
        font-size: 15px;
        letter-spacing: 0.75px;
    }

    .testimonial-part .slick-slide .testimonial-box .client-details-box h6 {
        font-size: 12px;
        letter-spacing: 0.6px;
    }

    .testimonial-part .slick-slide .testimonial-box .client-details-box {
        gap: 20px;
    }

    .testimonial-part .slick-slide .testimonial-box p {
        font-size: 10px;
        letter-spacing: 0.5px;
        width: 100% !important;
    }

    .testimonial-part .slick-slide {
        width: 300px !important;
        margin-top: 20px;
        height: auto;
        margin-left: 10px;
        margin-right: 10px;
    }

    .testimonial-part .slick-slide::before {
        width: 93%;
        height: 89%;
    }
}
@media only screen and (min-width: 375px) and (max-width :812px) {
    .testimonial-part .slick-slide {
        width:300px !important;
        margin: 14px 16px;
    }
}
@media only screen and (min-width: 390px) and (max-width :844px) {
    .testimonial-part .slick-slide {
        width: 320px !important;
        margin: 14px 15px;
    }
}
@media only screen and (min-width: 412px) and (max-width: 892px) {
    .testimonial-part .slick-slide {
        width: 350px !important;
        margin: 14px 1px;
    }
}
@media only screen and (min-width: 428px) and (max-width: 892px) {
    .testimonial-part .slick-slide {
        width: 360px !important;
        margin: 14px 14px;
    }
}

/* @media all and (min-width: 321px) and (max-width: 480px) {
    .testimonial-part {
        margin-top: 83px;
    }

    .testimonial-part .slick-slider .icon-left,
    .testimonial-part .slick-slider .icon-right {
        position: absolute;
        top: 120%;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 3px solid var(--orange-color);
        cursor: pointer;
        transition: 0.2s ease-in-out;
    }

    .testimonial-part .slick-slider .icon-left {
        left: 3%;
    }

    .testimonial-part .slick-slider .icon-right {
        right: 62%;
    }

    .testimonial-part .slick-slider .icon-left::before,
    .testimonial-part .slick-slider .icon-right::before {
        color: var(--orange-color);
    }

    .testimonial-part .testimonial-container {
        padding: 54px 20px 180px;
        overflow: hidden;
    }

    .testimonial-part .testimonial-content h6 {
        font-size: 15px;
        letter-spacing: 7.5px;
    }

    .testimonial-part .testimonial-content h1 {
        font-size: 20px;
        line-height: 20px;
    }

    .testimonial-part .testimonial-content p {
        font-size: 12px;
        letter-spacing: 0.6px;
        width: 100%;
    }

    .testimonial-part .slick-slide .testimonial-box {
        padding: 17px 13px;
    }

    .testimonial-part .slick-slide .testimonial-box .client-details-box img {
        width: 60px;
        height: 60px;
    }

    .testimonial-part .slick-slide .testimonial-box .client-details-box h1 {
        font-size: 15px;
        letter-spacing: 0.75px;
    }

    .testimonial-part .slick-slide .testimonial-box .client-details-box h6 {
        font-size: 12px;
        letter-spacing: 0.6px;
    }

    .testimonial-part .slick-slide .testimonial-box .client-details-box {
        gap: 20px;
    }

    .testimonial-part .slick-slide .testimonial-box p {
        font-size: 10px;
        letter-spacing: 0.5px;
        width: 100% !important;
    }

    .testimonial-part .slick-slide {
        width: 330px !important;
        margin-top: 20px;
        height: auto;
        margin-left: 10px;
        margin-right: 10px;
    }

    .testimonial-part .slick-slide::before {
        width: 93%;
        height: 89%;
    }
} */

@media screen and (min-width: 768px) and (max-width : 1024px) {
    .testimonial-part .testimonial-content h6 {
        font-size: 15px;
        letter-spacing: 7.5px;
    }

    .testimonial-part .testimonial-content h1 {
        font-size: 20px;
        line-height: 20px;
        text-align: left;
    }

    .testimonial-part .testimonial-content p {
        width: 100%;
        font-size: 12px;
    }

    .testimonial-part .testimonial-container {
        padding: 57px 40px;
        overflow: hidden;
    }

    .testimonial-part .slick-slide {
        width: 300px !important;
        margin: 14px 16px;
    }

    .testimonial-part .slick-slider .icon-left {
        left: -110%;
        top: 74%;
        background-color: var(--orange-color);
    }

    .testimonial-part .slick-slider .icon-right {
        left: -90%;
        top: 74%;
        background-color: var(--orange-color);
    }

    .testimonial-part .slick-slider .icon-left::before,
    .testimonial-part .slick-slider .icon-right::before {
        color: var(--white-color);
    }

}

/* iphone 11 */ 

/* iphone 13 */

/* ---------------------------------mobile device End --------------------- */


@media only screen and (min-width :1201px) and (max-width : 1440px) {
    .testimonial-part .testimonial-container {
        padding: 105px 100px;
    }
    .testimonial-part .testimonial-content h6 {
        font-size: 24px;
        width: 100%;
        letter-spacing: 8px;
    }

    .testimonial-part .testimonial-content h1 {
        width: 100%;
        font-size: 28px;
        text-align: left;
    }
    .testimonial-part .slick-slider .icon-right {
        right: 152%;
    }

    .testimonial-part .testimonial-content p {
        width: 100%;
    }

   
    .testimonial-part .slick-slide {
        width:535px !important;
        
    }
}
@media only screen and (min-width :1280px) and (max-width : 1439px) {
    
    .testimonial-part .slick-slide {
        width:582px !important;
        
    }
}

@media  screen and (min-width : 1440px) and (max-width : 1600px) {
    .testimonial-part .testimonial-content h6 {
        font-size: 24px;
        width: 100%;
        letter-spacing: 8px;
    }

    .testimonial-part .testimonial-content h1 {
        width: 100%;
        font-size: 28px;
        text-align: left;
    }

    .testimonial-part .testimonial-content p {
        width: 100%;
    }
   
    .testimonial-part .slick-slide {
        width:675px !important;
    }
}
@media only screen and (min-width : 1441px) and (max-width : 1600px) {
    .testimonial-part .testimonial-content h6 {
        font-size: 24px;
        width: 100%;
        letter-spacing: 8px;
    }

    .testimonial-part .testimonial-content h1 {
        width: 100%;
        font-size: 28px;
        text-align: left;
    }

    .testimonial-part .testimonial-content p {
        width: 100%;
    }

    .testimonial-part .slick-slide {
        width:624px !important;
        margin: 14px 15px;
    }
   
}
@media only screen and (min-width : 1600px) and (max-width : 1880px) {
    .testimonial-part .testimonial-content h6 {
        font-size: 24px;
        width: 100%;
        letter-spacing: 8px;
    }

    .testimonial-part .testimonial-content h1 {
        width: 100%;
        font-size: 28px;
        text-align: left;
    }

    .testimonial-part .testimonial-content p {
        width: 100%;
    }

    .testimonial-part .slick-slide {
        width:711px !important;
        margin: 14px 17px;
    }
    
}
/* ---------------------------------medai queery End----------------------- */