@import url('https://fonts.googleapis.com/css2?family=Archivo&family=Josefin+Sans:wght@700&family=Rubik&display=swap');

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px !important;
}

a {
    text-decoration: none !important;
}

:root {
    --josefin-text: 'Josefin Sans', sans-serif;
    --rubik-text: 'Rubik', sans-serif;
    --archivo-text: 'Archivo', sans-serif;
    --orange-color: #F6821F;
    --dark-blue-color: #005777;
    --white-color: #FFF;
}

/* ---------------------------------------------------- Section - 1  ---------------------------------------------------- */
.portfolio-section-1 {
    background: rgba(0, 87, 119, 0.05);
    height: auto;
}

.portfolio-section-1 .portfolio-container {
    width: 100% !important;
    padding: 120px 150px;
}

.portfolio-section-1 .portfolio-container .row {
    width: 100% !important;
}

.portfolio-section-1 .portfolio-content {
    width: 823px;
    margin-top: 110px;
}

.portfolio-section-1 .portfolio-content h1 {
    color: var(--dark-blue-color);
    font-family: var(--josefin-text);
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 135.5%;
    letter-spacing: 1px;
    text-transform: capitalize;
}

.portfolio-section-1 .portfolio-content span {
    color: var(--orange-color);
    font-family: var(--rubik-text);
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    line-height: 135.5%;
    letter-spacing: 0.9px;
    text-transform: capitalize;
}

.portfolio-section-1 .portfolio-content p {
    color: #696969;
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%;
    letter-spacing: 1px;
    text-transform: capitalize;
    margin-bottom: 82px !important;
}

.portfolio-section-1 .portfolio-content button {
    width: 210px;
    height: auto;
    border-radius: 50px;
    background: var(--orange-color);
    border: none;
    padding: 10px 19px;
    box-shadow: 0px 13px 20px rgba(0, 87, 119, 0.2);
}

.portfolio-section-1 .portfolio-content button a {
    color: var(--white-color);
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
}

.portfolio-section-1 .portfolio-img img {
    width: 831.918px;
    height: 656.772px;
    margin-left: 0px;
}

/* ---------------------------------------------------- Section - 2  ---------------------------------------------------- */
.portfolio-section-2 {
    height: auto;
    padding: 100px 0px;
}

.portfolio-section-2 .portfolio-container {
    width: 100% !important;
    padding: 0px 150px;
}

.portfolio-section-2 .title-part {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.portfolio-section-2 .title-part h6 {
    color: var(--orange-color);
    font-family: var(--rubik-text);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 15px;
    text-transform: uppercase;
}

.portfolio-section-2 .title-part h1 {
    color: var(--dark-blue-color);
    font-family: var(--josefin-text);
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 65px;
    text-transform: uppercase;
}

.portfolio-section-2 .portfolio-content {
    margin-top: 50px;
}

.portfolio-section-2 .portfolio-content .portfolio-menus {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 35px;
}

.portfolio-section-2 .portfolio-content .portfolio-menus .active-li button {
    padding: 14px 56px;
    border-radius: 50px;
    background: var(--orange-color);
    color: var(--white-color);
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 170%;
    border: none;
    box-shadow: 0px 8px 20px rgba(0, 87, 119, 0.3);
}

.portfolio-section-2 .portfolio-content .portfolio-menus button {
    padding: 14px 56px;
    border-radius: 50px;
    border: 1px solid #696969;
    background: var(--white-color);
    color: #696969;
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 170%;
}

.portfolio-section-2 .portfolio-card {
    margin-top: 100px;
    position: relative;
}

.portfolio-section-2 .portfolio-card::before {
    position: absolute;
    content: "";
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: 0.4s ease-in-out;
    border-radius: 5px;
    transform: scale(0.1);
}

.portfolio-section-2 .portfolio-card img {
    border-radius: 5px;
}

.portfolio-section-2 .portfolio-card .portfolio-card-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: 0.4s ease-in-out;
}

.portfolio-section-2 .portfolio-card .portfolio-card-content .link-icon {
    font-size: 26px;
    color: var(--white-color);
    margin-bottom: 30px;
}

.portfolio-section-2 .portfolio-card .portfolio-card-content h6 {
    color: var(--white-color);
    font-family: var(--josefin-text);
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.3%;
}

.portfolio-section-2 .portfolio-card .portfolio-card-content h1 {
    color: var(--white-color);
    font-family: var(--rubik-text);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 140.3%;
}

[data-aos="h1-animation"] {
    opacity: 0;
    transform: translateX(120px);
}

[data-aos="h6-animation"] {
    opacity: 0;
    transform: translateX(-120px);
}

[data-aos="link-icon-animation"] {
    opacity: 0;
    transform: scale(0.2);
}

.portfolio-section-2 .portfolio-card:hover::before {
    opacity: 1;
    transform: scale(1);
}

.portfolio-section-2 .portfolio-card:hover .portfolio-card-content {
    opacity: 1;
}

.portfolio-section-2 .portfolio-card:hover [data-aos="h1-animation"].aos-animate,
.portfolio-section-2 .portfolio-card:hover [data-aos="h6-animation"].aos-animate {
    transform: translateX(0);
    opacity: 1;
}

.portfolio-section-2 .portfolio-card:hover [data-aos="link-icon-animation"].aos-animate {
    opacity: 1;
    transform: scale(1);
}

.portfolio-section-2 .portfolio-card .border-animation-1::before {
    content: "";
    position: absolute;
    top: 21px;
    left: 18px;
    width: 0%;
    height: 2px;
    background-color: var(--white-color);
    transition: 0.2s;
}

.portfolio-section-2 .portfolio-card .border-animation-2::before {
    content: "";
    position: absolute;
    top: 21px;
    right: 18px;
    width: 2px;
    height: 0px;
    background-color: var(--white-color);
    transition: 0.2s;
}

.portfolio-section-2 .portfolio-card .border-animation-3::before {
    content: "";
    position: absolute;
    bottom: 21px;
    right: 18px;
    width: 0%;
    height: 2px;
    background-color: var(--white-color);
    transition: 0.2s;
}

.portfolio-section-2 .portfolio-card .border-animation-4::before {
    content: "";
    position: absolute;
    bottom: 21px;
    left: 18px;
    width: 2px;
    height: 0px;
    background-color: var(--white-color);
    transition: 0.2s;
}

.portfolio-section-2 .portfolio-card:hover .border-animation-1::before {
    width: calc(100% - 36px);
    transition-delay: 0s;
}

.portfolio-section-2 .portfolio-card:hover .border-animation-2::before {
    height: calc(100% - 42px);
    transition-delay: 0.2s;
}

.portfolio-section-2 .portfolio-card:hover .border-animation-3::before {
    width: calc(100% - 36px);
    transition-delay: 0.4s;
}

.portfolio-section-2 .portfolio-card:hover .border-animation-4::before {
    height: calc(100% - 42px);
    transition-delay: 0.6s;
}

/* ---------------------------------medai queery Start----------------------- */
/* ---------------------------------mobile device Start---------------------- */
/* ----------------one plus Start ---------------- */
@media only screen and (min-width: 412px) and (max-width: 892px) {
    .portfolio-section-1 .portfolio-container .row {
        margin: auto;
    }

    .portfolio-section-1 .portfolio-container,
    .portfolio-section-2 .portfolio-container {
        padding: 0 20px;
    }

    .portfolio-section-1 .portfolio-content {
        width: 100%;
        margin-top: 35px;
    }

    .portfolio-section-1 .portfolio-content h1,
    .portfolio-section-1 .portfolio-content span {
        font-size: 20px;
        letter-spacing: 0.4px;
    }

    .portfolio-section-1 .portfolio-content p {
        margin-bottom: 30px !important;
        font-size: 12px;
        margin-top: 10px !important;
    }

    .portfolio-section-1 .portfolio-img img {
        width: 100%;
        height: 100%;
        margin-top: 50px;
    }

    .portfolio-section-2 {
        padding: 80px 0;
    }

    .portfolio-section-2 .title-part h6 {
        font-size: 15px;
        letter-spacing: 7.5px;
    }

    .portfolio-section-2 .title-part h1 {
        font-size: 20px;
        line-height: 10px;
    }

    .portfolio-section-2 .portfolio-content {
        margin-top: 20px;
    }

    .portfolio-section-2 .portfolio-content .portfolio-menus button {
        width: 100%;
    }

    .portfolio-section-2 .portfolio-content .portfolio-menus {
        display: block;
    }

    .portfolio-section-2 .portfolio-content .portfolio-menus button {
        margin: 10px 0;
    }

    .portfolio-section-2 .portfolio-card {
        margin-top: 50px;
    }

    .portfolio-section-2 .portfolio-card .portfolio-card-content h1 {
        font-size: 19px;
        width: 200px;
        text-align: center;
    }

    .portfolio-section-2 .portfolio-card .portfolio-card-content h6 {
        font-size: 15px;
    }
}

/* ----------------one plus End ---------------- */
@media all and (min-width: 321px) and (max-width: 480px) {
    .portfolio-section-1 .portfolio-container .row ,    .portfolio-section-2 .portfolio-container .row 
        {
        margin: auto;
    }

    .portfolio-section-1 .portfolio-container,
    .portfolio-section-2 .portfolio-container {
        padding: 0 20px;
    }

    .portfolio-section-1 .portfolio-content {
        width: 100%;
        margin-top: 35px;
    }

    .portfolio-section-1 .portfolio-content h1,
    .portfolio-section-1 .portfolio-content span {
        font-size: 20px;
        letter-spacing: 0.4px;
    }

    .portfolio-section-1 .portfolio-content p {
        margin-bottom: 30px !important;
        font-size: 12px;
        margin-top: 10px !important;
    }

    .portfolio-section-1 .portfolio-img img {
        width: 100%;
        height: 100%;
        margin-top: 50px;
    }

    .portfolio-section-2 {
        padding: 80px 0;
    }

    .portfolio-section-2 .title-part h6 {
        font-size: 15px;
        letter-spacing: 7.5px;
    }

    .portfolio-section-2 .title-part h1 {
        font-size: 20px;
        line-height: 10px;
    }

    .portfolio-section-2 .portfolio-content {
        margin-top: 20px;
    }

    .portfolio-section-2 .portfolio-content .portfolio-menus button {
        width: 100%;
    }

    .portfolio-section-2 .portfolio-content .portfolio-menus {
        display: block;
    }

    .portfolio-section-2 .portfolio-content .portfolio-menus button {
        margin: 10px 0;
    }

    .portfolio-section-2 .portfolio-card {
        margin-top: 50px;
        overflow: hidden;
    }

    .portfolio-section-2 .portfolio-card .portfolio-card-content h1 {
        font-size: 19px;
        width: 200px;
        text-align: center;
    }

    .portfolio-section-2 .portfolio-card .portfolio-card-content h6 {
        font-size: 15px;
    }
}
/* ---------------------------------Tablet device Start-------------------- */
@media  screen and (min-width : 768px) and (max-width : 1024px) {
    .portfolio-section-1 .portfolio-container, .portfolio-section-2 .portfolio-container {
        padding: 0 40px;
        overflow: hidden;
    }
    .portfolio-section-1 .portfolio-img img {
        width: 100%;
        height: 100%;
        margin-top: 50px;
    }
    .portfolio-section-1 .portfolio-content h1, .portfolio-section-1 .portfolio-content span {
        font-size: 20px;
        width: 332px;
    }.portfolio-section-1 .portfolio-content p {
        width: 332px !important;
        font-size: 12px;
    }.portfolio-section-1 .portfolio-content button {
        margin-bottom: 30px;
        margin-top: 0px !important;
    }
    .portfolio-section-2 .portfolio-content .portfolio-menus {
        display: flex;
    }
    .portfolio-section-2 .portfolio-content .portfolio-menus button {
        padding: 10px 20px;
        border-radius: 50px;
        border: 1px solid #696969;
        background: var(--white-color);
        color: #696969;
        font-family: var(--rubik-text);
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 170%;
    }
}
/* ---------------------------------mobile device End---------------------- */
/* --------------------------screen size  1200px TO 1440px Start------- */
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .portfolio-section-1 .portfolio-container {
        padding: 120px 100px;
    }

    .portfolio-section-1 .portfolio-content h1 {
        font-size: 40px;
    }

    .portfolio-section-1 .portfolio-img img {
        width: 500px;
        height: 100%;
        margin-top: 122px;
    }

    .portfolio-section-1 .portfolio-content p {
        width: 600px;
    }

    .portfolio-section-1 .portfolio-content span {
        font-size: 40px;
    }

    .portfolio-section-2 .portfolio-card .portfolio-card-content h1 {
        font-size: 24px;
    }
}

/* --------------------------screen size  1200px TO 1440px End------- */
/* --------------------------screen size  1441px TO 1600px Start------- */
@media only screen and (min-width: 1441px) and (max-width: 1600px) {
    .portfolio-section-1 .portfolio-container {
        padding: 120px 100px;
    }

    .portfolio-section-1 .portfolio-content h1 {
        font-size: 40px;
    }

    .portfolio-section-1 .portfolio-img img {
        width: 650px;
        height: 100%;
        margin-top: 122px;
    }

    .portfolio-section-1 .portfolio-content p {
        width: 600px;
    }

    .portfolio-section-1 .portfolio-content span {
        font-size: 40px;
    }

    .portfolio-section-2 .portfolio-card .portfolio-card-content h1 {
        font-size: 24px;
    }
}

/* --------------------------screen size  1441px TO 1600px End------- */

/* ---------------------------------medai queery section1 start ------------- */