@import url('https://fonts.googleapis.com/css2?family=Archivo&family=Josefin+Sans:wght@700&family=Rubik&display=swap');

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px !important;
}

a {
    text-decoration: none !important;
}

:root {
    --josefin-text: 'Josefin Sans', sans-serif;
    --rubik-text: 'Rubik', sans-serif;
    --archivo-text: 'Archivo', sans-serif;
    --orange-color: #F6821F;
    --dark-blue-color: #005777;
    --white-color: #FFF;
}

/* ---------------------------------------------------- Main Blog Body ---------------------------------------------------- */

.blog-details-part {
    height: auto;
    padding-top: 100px;
    padding-bottom: 50px;
}

.blog-details-part .blog-details-container {
    padding: 0px 150px;
}

.main-blog-details-sectetion .blog-part .blog-container .row .col-xl-10 {
    display: none;
}

.main-blog-details-sectetion .blog-part .blog-container .row .col-xl-12 {
    display: block;
}

.blog-details-part .blog-body .blog-image {
    width: 1072px;
    height: 616px;
    position: relative;
}

.blog-details-part .blog-body .blog-image img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
}

.blog-details-part .blog-body .blog-content {
    border-radius: 5px;
    background: #F2F6F8;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.08);
    width: 860px;
    padding: 33px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin: auto;
    transform: translateY(-50%);
}

.blog-details-part .blog-body .blog-content p {
    color: var(--dark-blue-color);
    text-align: center;
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.blog-details-part .blog-body .blog-content h1 {
    color: var(--dark-blue-color);
    text-align: center;
    font-family: var(--josefin-text);
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
}

.blog-details-part .blog-description p {
    color: #696969;
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%;
    letter-spacing: 1px;
    text-transform: capitalize;
}

.why-use-part {
    margin-top: 50px;
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.why-use-part h1 {
    color: var(--dark-blue-color);
    font-family: var(--josefin-text);
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 190%;
    text-transform: capitalize;
    background-color: #F2F6F8;
    padding: 15px 34px 8px;
    border-left: 10px solid var(--dark-blue-color);
}

.why-use-part p {
    color: #696969;
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%;
    letter-spacing: 1px;
    text-transform: capitalize;
}

.main-blog-details-sectetion .blog-part .title-part {
    align-items: center;
}

/* .main-blog-details-sectetion .blog-part .blog-container .row .col-xl-10 {
    width: 100%;
} */

.main-blog-details-sectetion .blog-part .blog-container .row .col-xl-2 {
    display: none;
}

.reveal {
    position: relative;
    opacity: 0;
}

.reveal.active {
    opacity: 1;
}

.active.fade-left {
    animation: parthiv 1s ease-in;
}

@keyframes parthiv {
    from {
        opacity: 0;
        transform: scaleX(0);
    }

    to {
        opacity: 1;
        transform: scaleX(1);
    }
}

.reveal-1 {
    position: relative;
    opacity: 0;
}

.reveal-1.active {
    opacity: 1;
}

.active.why-use-part-fade-left {
    animation: why-use-animation 2s;
    animation-delay: 1.6s;
    animation-fill-mode: both;
}

@keyframes why-use-animation {
    from {
        opacity: 0;
        width: 0%;
    }

    to {
        opacity: 1;
        width: 100%;
    }
}

/* ----------------------------------------------- Media Query ----------------------------------------------- */
/* ---------------------------------mobile device Start---------------------- */
@media only screen and (min-width: 412px) and (max-width: 892px) {
    .blog-details-part .blog-details-container {
        padding: 0 20px;
    }

    .blog-details-part .blog-body .blog-image {
        width: 100%;
        height: 100%;
    }

    .blog-details-part .blog-body .blog-content {
        width: 280px;
    }

    .blog-details-part .blog-body .blog-content p {
        font-size: 12px;
    }

    .blog-details-part .blog-body .blog-content h1 {
        font-size: 15px;
    }

    .blog-details-part .blog-body .blog-content {
        gap: 0px;
        padding: 20px 0;
    }

    .blog-details-part .blog-description p {
        font-size: 12px;
        width: 100%;
    }

    .why-use-part h1 {
        padding: 19px 20px;
        font-size: 15px;
    }

    .why-use-part p {
        font-size: 12px;
        width: 100%;
        margin-bottom: 50px !important;
    }
}

@media all and (min-width: 321px) and (max-width: 480px) {
    .blog-details-part .blog-details-container {
        padding: 0 20px;
    }

    .blog-details-part .blog-body .blog-image {
        width: 100%;
        height: 100%;
    }

    .blog-details-part .blog-body .blog-content {
        width: 280px;
    }

    .blog-details-part .blog-body .blog-content p {
        font-size: 12px;
    }

    .blog-details-part .blog-body .blog-content h1 {
        font-size: 15px;
    }

    .blog-details-part .blog-body .blog-content {
        gap: 0px;
        padding: 20px 0;
    }

    .blog-details-part .blog-description p {
        font-size: 12px;
        width: 100%;
    }

    .why-use-part h1 {
        padding: 19px 20px;
        font-size: 15px;
    }

    .why-use-part p {
        font-size: 12px;
        width: 100%;
        margin-bottom: 50px !important;
    }

    .main-blog-details-sectetion .blog-part .blog-container .row .col-xl-2 {
        display: block;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .blog-details-part {
        padding: 60px 0px;
    }

    .blog-details-part .blog-details-container {
        padding: 0 40px;
    }

    .blog-details-part .blog-body .blog-image {
        width: 100%;
        height: 100%;
    }

    .blog-details-part .blog-body .blog-content {
        width: 280px;
        gap: 10px;
        padding: 20px 0;
    }

    .blog-details-part .blog-body .blog-content p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 10px;
    }

    .blog-details-part .blog-body .blog-content h1 {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .blog-details-part .blog-description p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.36px;
    }

    .why-use-part h1 {
        padding: 19px 20px;
        font-size: 15px;
        border-left-width: 7px;
        font-weight: 700;
        line-height: normal;
    }

    .why-use-part p {
        font-size: 12px;
        width: 100%;
        margin-bottom: 50px !important;
        font-weight: 500;
        line-height: 160%;
        letter-spacing: 0.36px;
    }

    .main-blog-details-sectetion .blog-part .blog-container .row .col-xl-2 {
        display: block;
    }

    .main-blog-details-sectetion .blog-part .title-part {
        align-items: flex-start;
    }
}

/* ---------------------------------mobile device End---------------------- */

@media only screen and (min-width: 1201px) and (max-width: 1339px) {

    /* ---------------------------------------------------- Section - 1 ---------------------------------------------------- */
    .blog-details-part {
        padding-top: 80px;
    }

    .blog-details-part .blog-details-container {
        padding: 0px 100px;
    }

    .blog-details-part .blog-body .blog-image {
        width: 100%;
        height: 450px;
    }

    .blog-details-part .blog-body .blog-content {
        width: 530px;
        padding: 24px 0px;
        gap: 8px;
    }

    .blog-details-part .blog-body .blog-content p {
        font-size: 18px;
    }

    .blog-details-part .blog-body .blog-content h1 {
        font-size: 28px;
    }
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {

    /* ---------------------------------------------------- Section - 1 ---------------------------------------------------- */
    .blog-details-part {
        padding-top: 80px;
    }

    .blog-details-part .blog-details-container {
        padding: 0px 100px;
    }

    .blog-details-part .blog-body .blog-image {
        width: 100%;
        height: 520px;
    }

    .blog-details-part .blog-body .blog-content {
        width: 530px;
        padding: 24px 0px;
        gap: 8px;
    }

    .blog-details-part .blog-body .blog-content p {
        font-size: 18px;
    }

    .blog-details-part .blog-body .blog-content h1 {
        font-size: 28px;
    }
}