@import url('https://fonts.googleapis.com/css2?family=Archivo&family=Josefin+Sans:wght@700&family=Rubik&display=swap');

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px !important;
}

a {
    text-decoration: none !important;
}

:root {
    --josefin-text: 'Josefin Sans', sans-serif;
    --rubik-text: 'Rubik', sans-serif;
    --archivo-text: 'Archivo', sans-serif;
    --orange-color: #F6821F;
    --dark-blue-color: #005777;
    --white-color: #FFF;
}

::-webkit-scrollbar {
    display: none !important;
}

body {
    overflow-x: hidden;
}

/* ---------------------------------------------------- Section - 1 ---------------------------------------------------- */
.about-section-1 {
    background: rgba(0, 87, 119, 0.05);
    height: auto;
}

.about-section-1 .about-container {
    width: 100% !important;
    padding: 138px 0px 0px 150px;
}

/* .about-section-1 .about-container .row {
    width: 100% !important;
} */

.about-section-1 .about-content {
    width: 823px;
    margin-top: 82px;
}

.about-section-1 .about-content h1 {
    color: var(--dark-blue-color);
    font-family: var(--josefin-text);
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 135.5%;
    letter-spacing: 1px;
    text-transform: capitalize;
}

.about-section-1 .about-content span {
    color: var(--orange-color);
    font-family: var(--rubik-text);
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    line-height: 135.5%;
    letter-spacing: 0.9px;
    text-transform: capitalize;
}

.about-section-1 .about-content p {
    color: #696969;
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%;
    letter-spacing: 1px;
    text-transform: capitalize;
    margin-bottom: 82px !important;
}

.about-section-1 .about-content button {
    width: 210px;
    height: auto;
    border-radius: 50px;
    background: var(--orange-color);
    border: none;
    padding: 10px 19px;
    box-shadow: 0px 13px 20px rgba(0, 87, 119, 0.2);
}

.about-section-1 .about-content button a {
    color: var(--white-color);
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
}

.about-section-1 .about-img img {
    width: 870px;
    height: 680px;
    margin-left: 12px;
}

/* ---------------------------------------------------- Section - 2 ---------------------------------------------------- */
.about-section-2 {
    height: auto;
}

.about-section-2 .about-container {
    width: 100% !important;
    padding: 100.24px 144px 148px 171.76px;
}

.about-section-2 .about-img img {
    width: 707.244px;
    height: 721.344px;
}

.about-section-2 .about-content {
    margin-top: 50px;
}

.about-section-2 .about-content h6 {
    color: var(--orange-color);
    font-family: var(--rubik-text);
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 15px;
    text-transform: uppercase;
}

.about-section-2 .about-content h1 {
    color: var(--dark-blue-color);
    font-family: var(--josefin-text);
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 65px;
    text-transform: uppercase;
    width: 826px;
    margin: 10px 0px !important;
}

.about-section-2 .about-content .about-content-paragraph {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
}

.about-section-2 .about-content .about-content-paragraph p {
    color: #696969;
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%;
    letter-spacing: 1px;
    text-transform: capitalize;
}

/* ---------------------------------------------------- Section - 3 ---------------------------------------------------- */
.about-section-3 {
    background: #F2F6F8;
}

.about-section-3 .about-container {
    width: 100% !important;
    padding: 120px 150px;
}

.about-section-3 .about-content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-section-3 .about-content-box .about-counter-digit {
    color: var(--orange-color);
    font-family: var(--rubik-text);
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 170%;
}

.about-section-3 .about-content-box p {
    color: var(--dark-blue-color);
    font-family: var(--rubik-text);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 170%;
}

/* ---------------------------------------------------- Section - 4 ---------------------------------------------------- */

.about-section-4 .about-container {
    width: 100% !important;
    padding: 100px 150px;
}

.about-section-4 .title-part {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
}

.about-section-4 .title-part h6 {
    color: var(--orange-color);
    font-family: var(--rubik-text);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 15px;
    text-transform: uppercase;
}

.about-section-4 .title-part h1 {
    color: var(--dark-blue-color);
    font-family: var(--josefin-text);
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 65px;
    text-transform: uppercase;
}

.about-section-4 .expert-member-slider {
    margin-top: 100px;
    position: relative;
}

.about-section-4 .expert-member-slider .slick-slide {
    width: 387px !important;
    height: 501px;
    margin: 0px 9px;
    border-radius: 5px;
}

.about-section-4 .expert-member-slider .slick-dots {
    position: absolute;
    bottom: -50px;
    left: 0px;
    right: 0px;
}

.about-section-4 .expert-member-slider .slick-dots li.slick-active button:before {
    font-size: 16px;
    color: var(--orange-color);
}

.about-section-4 .expert-member-slider .slick-dots li button:before {
    color: #FBC18F;
    opacity: 0.6;
}

.about-section-4 .expert-member-slider .slider-box {
    position: relative;
    border-radius: 5px;
}

.about-section-4 .expert-member-slider .slider-box img {
    border-radius: 5px;
    width: 100%;
    height: 100%;
}

.about-section-4 .expert-member-slider .slider-box::before {
    position: absolute;
    content: "";
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: 0.4s ease-in-out;
    border-radius: 5px;
    transform: scale(0.1);
}

.about-section-4 .expert-member-slider .slider-box .slider-box-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2px;
    width: 90%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: 0.4s ease-in-out;
}

.about-section-4 .expert-member-slider .slider-box .slider-box-content h1 {
    color: var(--white-color);
    text-align: center;
    font-family: var(--rubik-text);
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 190%;
    letter-spacing: 1.25px;
    text-transform: capitalize;
}

.about-section-4 .expert-member-slider .slider-box .slider-box-content h6 {
    color: var(--white-color);
    text-align: center;
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 190%;
    letter-spacing: 1px;
    text-transform: capitalize;
}

[data-aos="h1-animation"] {
    opacity: 0;
    transform: translateX(120px);
}

[data-aos="h6-animation"] {
    opacity: 0;
    transform: translateX(-120px);
}

.about-section-4 .expert-member-slider .slider-box:hover::before {
    opacity: 1;
    transform: scale(1);
}

.about-section-4 .expert-member-slider .slider-box:hover .slider-box-content {
    opacity: 1;
}

.about-section-4 .expert-member-slider .slider-box:hover .slider-box-content h1 {
    color: var(--orange-color);
}

.about-section-4 .expert-member-slider .slider-box:hover [data-aos="h1-animation"].aos-animate,
.about-section-4 .expert-member-slider .slider-box:hover [data-aos="h6-animation"].aos-animate {
    transform: translateX(0);
    opacity: 1;
}

.about-section-4 .expert-member-slider .border-animation-1::before {
    content: "";
    position: absolute;
    top: 21px;
    left: 16px;
    width: 0%;
    height: 2px;
    background-color: var(--white-color);
    transition: 0.2s;
}

.about-section-4 .expert-member-slider .border-animation-2::before {
    content: "";
    position: absolute;
    top: 21px;
    right: 16px;
    width: 2px;
    height: 0px;
    background-color: var(--white-color);
    transition: 0.2s;
    transition-delay: calc(0.2s * 2);
}

.about-section-4 .expert-member-slider .border-animation-3::before {
    content: "";
    position: absolute;
    bottom: 21px;
    right: 16px;
    width: 0%;
    height: 2px;
    background-color: var(--white-color);
    transition: 0.2s;
    transition-delay: calc(0.2s * 3);
}

.about-section-4 .expert-member-slider .border-animation-4::before {
    content: "";
    position: absolute;
    bottom: 21px;
    left: 16px;
    width: 2px;
    height: 0px;
    background-color: var(--white-color);
    transition: 0.2s;
    transition-delay: calc(0.2s * 4);
}

.about-section-4 .expert-member-slider .slider-box:hover .border-animation-1::before {
    width: calc(100% - 32px);
}

.about-section-4 .expert-member-slider .slider-box:hover .border-animation-2::before {
    height: calc(100% - 42px);
}

.about-section-4 .expert-member-slider .slider-box:hover .border-animation-3::before {
    width: calc(100% - 32px);
}

.about-section-4 .expert-member-slider .slider-box:hover .border-animation-4::before {
    height: calc(100% - 42px);
}

/* ----------------------------------------------- Media Query ----------------------------------------------- */
@media all and (min-width: 321px) and (max-width: 480px) {

    /* ---------------------------------------------------- Section - 1 ---------------------------------------------------- */
    .about-section-1 .about-container {
        padding: 40px 20px 0px;
    }

    .about-section-1 .about-content {
        margin-top: 0px;
        width: 100%;
    }

    .about-section-1 .about-content h1 {
        font-size: 19px;
        font-style: normal;
        font-weight: 700;
        line-height: 135.5%;
        letter-spacing: 0.4px;
        width: 325px;
    }

    .about-section-1 .about-content span {
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 135.5%;
        letter-spacing: 0.4px;
    }

    .about-section-1 .about-content p {
        margin-top: 11px !important;
        margin-bottom: 40px !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.36px;
        width: 325px;
    }

    .about-section-1 .about-content button {
        padding: 8px 10px;
        width: 130px;
    }

    .about-section-1 .about-content button a {
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 170%;
    }

    .about-section-1 .about-img img {
        margin-left: 0px;
        margin-top: 70px;
        width: 100%;
        height: 100%;
    }

    /* ---------------------------------------------------- Section - 2 ---------------------------------------------------- */
    .about-section-2 .about-container {
        padding: 80px 20px;
    }

    .about-section-2 .about-container .row {
        flex-direction: column-reverse;
        gap: 50px;
    }

    .about-section-2 .about-content {
        margin-top: 0px;
    }

    .about-section-2 .about-content h6 {
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 7.5px;
    }

    .about-section-2 .about-content h1 {
        font-size: 19px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0.4px;
        width: 100%;
    }

    .about-section-2 .about-content .about-content-paragraph {
        gap: 10px;
    }

    .about-section-2 .about-content .about-content-paragraph p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.36px;
    }

    .about-section-2 .about-img img {
        width: 100%;
        height: 100%;
    }

    /* ---------------------------------------------------- Section - 3 ---------------------------------------------------- */
    .about-section-3 .about-container {
        padding: 60px 100px;
    }

    .about-section-3 .about-container .row {
        flex-direction: column;
        gap: 50px;
    }

    .about-section-3 .about-content-box .about-counter-digit {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
    }

    .about-section-3 .about-content-box {
        gap: 10px;
    }

    .about-section-3 .about-content-box p {
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
    }

    /* ---------------------------------------------------- Section - 4 ---------------------------------------------------- */
    .about-section-4 .about-container {
        padding: 80px 0px;
        overflow: hidden;
    }

    .about-section-4 .title-part h6 {
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 7.5px;
    }

    .about-section-4 .title-part h1 {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .about-section-4 .expert-member-slider {
        padding: 20px 0px;
        margin-top: 50px;
    }

    .about-section-4 .expert-member-slider .slick-slide {
        width: 220px !important;
        height: auto;
        margin: 0px 10px;
        transform: scale(0.8);
    }

    .about-section-4 .expert-member-slider .slick-active {
        transform: scale(1);
    }

    .about-section-4 .expert-member-slider .slider-box .slider-box-content {
        gap: 7px;
    }

    .about-section-4 .expert-member-slider .slider-box .slider-box-content h1 {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: 0.6px;
    }

    .about-section-4 .expert-member-slider .slider-box .slider-box-content h6 {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: 0.5px;
    }
}
@media only screen and (min-width:375px) and (max-width:812px) {
    .about-section-4 .expert-member-slider {
        padding: 20px 0px;
        margin-top: 50px;
    }

    .about-section-4 .expert-member-slider .slick-slide {
        width: 260px !important;
        height: auto;
        margin: 0px 5px;
        transform: scale(0.8);
    }

    .about-section-4 .expert-member-slider .slick-active {
        transform: scale(1);
    }

    .about-section-4 .expert-member-slider .slider-box .slider-box-content {
        gap: 7px;
    }

    .about-section-4 .expert-member-slider .slider-box .slider-box-content h1 {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: 0.6px;
    }

    .about-section-4 .expert-member-slider .slider-box .slider-box-content h6 {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: 0.5px;
    }
}
@media only screen and (min-width: 412px) and (max-width: 992px) {

    /* ---------------------------------------------------- Section - 1 ---------------------------------------------------- */
    .about-section-1 .about-container {
        padding: 40px 20px 0px;
    }

    .about-section-1 .about-content {
        margin-top: 0px;
        width: 100%;
    }

    .about-section-1 .about-content h1 {
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 135.5%;
        letter-spacing: 0.4px;
        width: 100%;
    }

    .about-section-1 .about-content span {
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 135.5%;
        letter-spacing: 0.4px;
    }

    .about-section-1 .about-content p {
        margin-top: 11px !important;
        margin-bottom: 40px !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.36px;
        width: 100%;
    }

    .about-section-1 .about-content button {
        padding: 10px 18px;
        width: auto;
    }

    .about-section-1 .about-content button a {
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 170%;
    }

    .about-section-1 .about-img img {
        margin-left: 0px;
        margin-top: 70px;
        width: 100%;
        height: 100%;
    }

    /* ---------------------------------------------------- Section - 2 ---------------------------------------------------- */
    .about-section-2 .about-container {
        padding: 80px 20px;
    }

    .about-section-2 .about-container .row {
        flex-direction: column-reverse;
        gap: 50px;
    }

    .about-section-2 .about-content {
        margin-top: 0px;
    }

    .about-section-2 .about-content h6 {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 7.5px;
    }

    .about-section-2 .about-content h1 {
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0.4px;
        width: 100%;
    }

    .about-section-2 .about-content .about-content-paragraph {
        gap: 10px;
    }

    .about-section-2 .about-content .about-content-paragraph p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.36px;
    }

    .about-section-2 .about-img img {
        width: 100%;
        height: 100%;
    }

    /* ---------------------------------------------------- Section - 3 ---------------------------------------------------- */
    .about-section-3 .about-container {
        padding: 60px 100px;
    }

    .about-section-3 .about-container .row {
        flex-direction: column;
        gap: 50px;
    }

    .about-section-3 .about-content-box .about-counter-digit {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
    }

    .about-section-3 .about-content-box {
        gap: 10px;
    }

    .about-section-3 .about-content-box p {
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
    }

    /* ---------------------------------------------------- Section - 4 ---------------------------------------------------- */

    .about-section-4 .about-container {
        padding: 80px 0px 50px;
    }

    .about-section-4 .title-part h6 {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 7.5px;
    }

    .about-section-4 .title-part h1 {
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .about-section-4 .expert-member-slider {
        padding: 20px 0px;
        margin-top: 80px;
    }

    .about-section-4 .expert-member-slider .slick-slide {
        width: 292px !important;
        height: auto;
        margin: 0px;
        transform: scale(0.8);
    }

    .about-section-4 .expert-member-slider .slick-active {
        transform: scale(1);
    }

    .about-section-4 .expert-member-slider .slider-box .slider-box-content {
        gap: 10px;
    }

    .about-section-4 .expert-member-slider .slider-box .slider-box-content h1 {
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: 0.6px;
    }

    .about-section-4 .expert-member-slider .slider-box .slider-box-content h6 {
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: 0.5px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

    /* ---------------------------------------------------- Section - 1 ---------------------------------------------------- */
    .about-section-1 .about-container {
        padding: 60px 0px 0px 40px;
    }

    .about-section-1 .about-container .row {
        width: 100%;
    }

    .about-section-1 .about-content {
        margin-top: 0px;
    }

    .about-section-1 .about-content h1 {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 135.5%;
        letter-spacing: 0.4px;
        width: 100%;
    }

    .about-section-1 .about-content span {
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 135.5%;
        letter-spacing: 0.4px;
    }

    .about-section-1 .about-content p {
        margin-top: 12px !important;
        margin-bottom: 30px !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.36px;
        width: 100%;
    }

    .about-section-1 .about-content button {
        padding: 10px 12px;
        width: auto;
    }

    .about-section-1 .about-content button a {
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
    }

    .about-section-1 .about-img img {
        margin-left: 22px;
        margin-top: 31px;
        width: 100%;
        height: 100%;
    }

    /* ---------------------------------------------------- Section - 2 ---------------------------------------------------- */
    .about-section-2 .about-container {
        padding: 50px 40px;
    }

    .about-section-2 .about-container .row {
        flex-direction: row;
        gap: 0px;
    }

    .about-section-2 .about-content {
        margin-top: 0px;
    }

    .about-section-2 .about-content h6 {
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 7.5px;
    }

    .about-section-2 .about-content h1 {
        font-size: 19px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0.4px;
        width: 100%;
    }

    .about-section-2 .about-content .about-content-paragraph {
        gap: 10px;
    }

    .about-section-2 .about-content .about-content-paragraph p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.36px;
    }

    .about-section-2 .about-img img {
        width: 100%;
        height: 100%;
        margin-top: 20px;
    }

    /* ---------------------------------------------------- Section - 3 ---------------------------------------------------- */
    .about-section-3 .about-container {
        padding: 52px 40px;
    }

    .about-section-3 .about-container .row {
        flex-direction: row;
        gap: 0px;
    }

    .about-section-3 .about-content-box {
        gap: 10px;
    }

    .about-section-3 .about-content-box .about-counter-digit {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
    }

    .about-section-3 .about-content-box p {
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
    }

    /* ---------------------------------------------------- Section - 4 ---------------------------------------------------- */

    .about-section-4 .about-container {
        padding: 50px 40px;
    }

    .about-section-4 .title-part h6 {
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 7.5px;
    }

    .about-section-4 .title-part h1 {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .about-section-4 .expert-member-slider {
        padding: 20px 0px;
        margin-top: 30px;
    }

    .about-section-4 .expert-member-slider .slick-slide {
        width: 210px !important;
        height: auto;
        margin: 0px 10px;
        transform: scale(1);
    }

    .about-section-4 .expert-member-slider .slider-box .slider-box-content {
        gap: 10px;
    }

    .about-section-4 .expert-member-slider .slider-box .slider-box-content h1 {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: 0.6px;
    }

    .about-section-4 .expert-member-slider .slider-box .slider-box-content h6 {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: 0.5px;
    }
}
@media  screen and (min-width: 1201px) and (max-width: 1440px) {

    /* ---------------------------------------------------- Section - 1 ---------------------------------------------------- */
    .about-section-1 .about-container {
        padding: 100px 100px;
        width: 100% !important;
    }

    .about-section-1 .about-content {
        width: 470px;
    }

    .about-section-1 .about-content h1,
    .about-section-1 .about-content span {
        font-size: 40px;
    }

    .about-section-1 .about-content p {
        margin-bottom: 60px !important;
    }

    .about-section-1 .about-img img {
        margin: 0px;
        width: 600px;
        height: 600px;
    }

    /* ---------------------------------------------------- Section - 2 ---------------------------------------------------- */
    .about-section-2 .about-container {
        padding: 100px 100px 140px;
    }

    .about-section-2 .about-img {
        margin-top: 40px;
    }

    .about-section-2 .about-img img {
        width: 490px;
        height: 490px;
    }

    .about-section-2 .about-content {
        margin-top: 0px;
    }

    .about-section-2 .about-content h6 {
        font-size: 26px;
        letter-spacing: 10px;
    }

    .about-section-2 .about-content h1 {
        font-size: 29px;
        width: 100%;
        line-height: 40px;
    }

    .about-section-2 .about-content .about-content-paragraph p {
        font-size: 16px;
        line-height: 170%;
    }

    /* ---------------------------------------------------- Section - 3 ---------------------------------------------------- */
    .about-section-3 .about-container {
        padding: 100px 100px;
    }

    .about-section-3 .about-content-box .about-counter-digit {
        font-size: 50px;
    }

    .about-section-3 .about-content-box p {
        font-size: 22px;
    }

    /* ---------------------------------------------------- Section - 4 ---------------------------------------------------- */
    .about-section-4 .about-container {
        padding: 100px 100px;
    }

    .about-section-4 .title-part h6 {
        font-size: 26px;
        letter-spacing: 12px;
    }

    .about-section-4 .title-part h1 {
        font-size: 46px;
    }

    .about-section-4 .expert-member-slider {
        margin-top: 70px;
    }

    .about-section-4 .expert-member-slider .slick-slide {
        width: 243px !important;
        height: auto;
        margin: 0px 4px;
        border-radius: 5px;
    }
}
@media  screen and (min-width: 1280px) and (max-width: 1440px) {

    /* ---------------------------------------------------- Section - 1 ---------------------------------------------------- */
    .about-section-1 .about-container {
        padding: 100px 100px;
        width: 100% !important;
    }

    .about-section-1 .about-content {
        width: 470px;
    }

    .about-section-1 .about-content h1,
    .about-section-1 .about-content span {
        font-size: 40px;
    }

    .about-section-1 .about-content p {
        margin-bottom: 60px !important;
    }

    .about-section-1 .about-img img {
        margin: 0px;
        width: 600px;
        height: 600px;
    }

    /* ---------------------------------------------------- Section - 2 ---------------------------------------------------- */
    .about-section-2 .about-container {
        padding: 100px 100px 140px;
    }

    .about-section-2 .about-img {
        margin-top: 40px;
    }

    .about-section-2 .about-img img {
        width: 490px;
        height: 490px;
    }

    .about-section-2 .about-content {
        margin-top: 0px;
    }

    .about-section-2 .about-content h6 {
        font-size: 26px;
        letter-spacing: 10px;
    }

    .about-section-2 .about-content h1 {
        font-size: 29px;
        width: 100%;
        line-height: 40px;
    }

    .about-section-2 .about-content .about-content-paragraph p {
        font-size: 16px;
        line-height: 170%;
    }

    /* ---------------------------------------------------- Section - 3 ---------------------------------------------------- */
    .about-section-3 .about-container {
        padding: 100px 100px;
    }

    .about-section-3 .about-content-box .about-counter-digit {
        font-size: 50px;
    }

    .about-section-3 .about-content-box p {
        font-size: 22px;
    }

    /* ---------------------------------------------------- Section - 4 ---------------------------------------------------- */
    .about-section-4 .about-container {
        padding: 100px 100px;
    }

    .about-section-4 .title-part h6 {
        font-size: 26px;
        letter-spacing: 12px;
    }

    .about-section-4 .title-part h1 {
        font-size: 46px;
    }

    .about-section-4 .expert-member-slider {
        margin-top: 70px;
    }

    .about-section-4 .expert-member-slider .slick-slide {
        width: 240px !important;
        height: auto;
        margin: 0px 15px;
        border-radius: 5px;
    }
}
@media only screen and (min-width: 1440px) and (max-width: 1600px) {

    /* ---------------------------------------------------- Section - 1 ---------------------------------------------------- */
    .about-section-1 .about-container {
        padding: 100px 100px;
        width: 100% !important;
    }

    .about-section-1 .about-content {
        width: 500px;
    }

    .about-section-1 .about-content h1,
    .about-section-1 .about-content span {
        font-size: 42px;
    }

    .about-section-1 .about-content p {
        margin-bottom: 60px !important;
        width: 560px;
    }

    .about-section-1 .about-img img {
        margin: 0px;
        width: 717px;
        height: 700px;
    }

    /* ---------------------------------------------------- Section - 2 ---------------------------------------------------- */
    .about-section-2 .about-container {
        padding: 100px 100px 140px;
    }

    .about-section-2 .about-img {
        margin-top: 20px;
    }

    .about-section-2 .about-img img {
        width: 520px;
        height: 520px;
    }

    .about-section-2 .about-content {
        margin-top: 0px;
    }

    .about-section-2 .about-content h6 {
        font-size: 26px;
        letter-spacing: 10px;
    }

    .about-section-2 .about-content h1 {
        font-size: 29px;
        width: 100%;
        line-height: 40px;
    }

    .about-section-2 .about-content .about-content-paragraph p {
        font-size: 16px;
        line-height: 170%;
    }

    /* ---------------------------------------------------- Section - 3 ---------------------------------------------------- */
    .about-section-3 .about-container {
        padding: 100px 100px;
    }

    .about-section-3 .about-content-box .about-counter-digit {
        font-size: 50px;
    }

    .about-section-3 .about-content-box p {
        font-size: 24px;
    }

    /* ---------------------------------------------------- Section - 4 ---------------------------------------------------- */
    .about-section-4 .about-container {
        padding: 100px 100px;
    }

    .about-section-4 .title-part h6 {
        font-size: 26px;
        letter-spacing: 12px;
    }

    .about-section-4 .title-part h1 {
        font-size: 46px;
    }

    .about-section-4 .expert-member-slider {
        margin-top: 70px;
    }

    .about-section-4 .expert-member-slider .slick-slide {
        width: 290px !important;
        height: auto;
        margin: 0px 10px;
        border-radius: 5px;
    }
}
@media only screen and (min-width: 1600px) and (max-width: 1880px) {

    /* ---------------------------------------------------- Section - 1 ---------------------------------------------------- */
    .about-section-1 .about-container {
        padding: 100px 100px;
        width: 100% !important;
    }

    .about-section-1 .about-content {
        width: 500px;
    }

    .about-section-1 .about-content h1,
    .about-section-1 .about-content span {
        font-size: 42px;
    }

    .about-section-1 .about-content p {
        margin-bottom: 60px !important;
        width: 560px;
    }

    .about-section-1 .about-img img {
        margin: 0px;
        width: 717px;
        height: 700px;
    }

    /* ---------------------------------------------------- Section - 2 ---------------------------------------------------- */
    .about-section-2 .about-container {
        padding: 100px 100px 140px;
    }

    .about-section-2 .about-img {
        margin-top: 20px;
    }

    .about-section-2 .about-img img {
        width: 520px;
        height: 520px;
    }

    .about-section-2 .about-content {
        margin-top: 0px;
    }

    .about-section-2 .about-content h6 {
        font-size: 26px;
        letter-spacing: 10px;
    }

    .about-section-2 .about-content h1 {
        font-size: 29px;
        width: 100%;
        line-height: 40px;
    }

    .about-section-2 .about-content .about-content-paragraph p {
        font-size: 16px;
        line-height: 170%;
    }

    /* ---------------------------------------------------- Section - 3 ---------------------------------------------------- */
    .about-section-3 .about-container {
        padding: 100px 100px;
    }

    .about-section-3 .about-content-box .about-counter-digit {
        font-size: 50px;
    }

    .about-section-3 .about-content-box p {
        font-size: 24px;
    }

    /* ---------------------------------------------------- Section - 4 ---------------------------------------------------- */
    .about-section-4 .about-container {
        padding: 100px 100px;
    }

    .about-section-4 .title-part h6 {
        font-size: 26px;
        letter-spacing: 12px;
    }

    .about-section-4 .title-part h1 {
        font-size: 46px;
    }

    .about-section-4 .expert-member-slider {
        margin-top: 70px;
    }

    .about-section-4 .expert-member-slider .slick-slide {
        width: 320px !important;
        height: auto;
        margin: 0px 15px;
        border-radius: 5px;
    }
}
