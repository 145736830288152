@import url('https://fonts.googleapis.com/css2?family=Archivo&family=Josefin+Sans:wght@700&family=Rubik&display=swap');

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px !important;
}

a {
    text-decoration: none !important;
}

:root {
    --josefin-text: 'Josefin Sans', sans-serif;
    --rubik-text: 'Rubik', sans-serif;
    --archivo-text: 'Archivo', sans-serif;
    --orange-color: #F6821F;
    --dark-blue-color: #005777;
    --white-color: #FFF;
}

.footer-part-top {
    padding-top: 50px;
}

.footer-part-top .footer-container {
    width: 100%;
}

.footer-part-top .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 13px;
}

.footer-part-top .footer-content h1 {
    color: var(--dark-blue-color);
    font-family: var(--josefin-text);
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.footer-part-top .footer-content p {
    color: var(--dark-blue-color);
    font-family: var(--archivo-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.footer-part {
    background: var(--dark-blue-color);
    position: relative;
    margin-top: 100px;
    width: 100% !important;
}

.footer-part .footer-container .contact-btn {
    color: var(--orange-color);
    font-family: var(--archivo-text);
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 50px;
    border: 2px solid #F6821F;
    background: #FFF;
    box-shadow: 0px 0px 39px 0px rgba(0, 0, 0, 0.25);
    padding: 22px 63px;
    position: absolute;
    top: -6%;
    left: 50%;
    transform: translate(-50%, -6%);
    z-index: 1;
}

.footer-part .footer-container {
    padding: 143px 154px;
    width: 100% !important;
}

.footer-part .footer-first-part {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.footer-part .footer-first-part .logo-body img {
    width: 158px;
    height: 46px;
}

.footer-part .footer-first-part p {
    color: var(--white-color);
    font-family: var(--rubik-text);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.6px;
    text-transform: capitalize;
    width: 380px;
}

.footer-part .footer-first-part ul {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 10px;
    margin-bottom: 0px;
}

.footer-part .footer-first-part ul li {
    background-color: var(--white-color);
    width: 30px;
    height: 30px;
    border-radius: 15px;
    position: relative;
}

.footer-part .footer-first-part ul li a img {
    width: 17.143px;
    height: 17.143px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.footer-part .company-part,
.footer-part .service-part,
.footer-part .contact-part {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-left: 120px;
}

.footer-part .service-part {
    margin-left: 50px;
}

.footer-part .contact-part {
    margin-left: 0px;
    width: 325px;
}

.footer-part .company-part h4,
.footer-part .service-part h4,
.footer-part .contact-part h4 {
    color: var(--white-color);
    font-family: var(--josefin-text);
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
}

.footer-part .company-part ul,
.footer-part .service-part ul,
.footer-part .contact-part ul {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
}

.footer-part .company-part ul li a,
.footer-part .service-part ul li a {
    color: var(--white-color);
    font-family: var(--rubik-text);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.footer-part .contact-part ul li {
    color: var(--white-color);
    font-family: var(--rubik-text);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 20px;
}

.footer-part .contact-part ul li i {
    font-size: 20px;
}

.footer-part .contact-part ul li:last-child i {
    margin-right: 6px;
}

/* ----------------------------------------------- Media Query ----------------------------------------------- */
@media only screen and (min-width: 1201px) and (max-width: 1339px) {
    .footer-part-top {
        overflow: hidden;
    }

    .footer-part-top .footer-content h1 {
        font-size: 40px;
    }

    .footer-part-top .footer-content p {
        font-size: 18px;
    }

    .footer-part .footer-container {
        padding: 140px 100px;
    }

    .footer-part .footer-container .contact-btn {
        font-size: 22px;
    }

    .footer-part .footer-first-part p {
        font-size: 12px;
        width: 254px;
    }

    .footer-part .company-part h4,
    .footer-part .service-part h4,
    .footer-part .contact-part h4 {
        font-size: 20px;
    }

    .footer-part .company-part {
        margin-left: 60px;
        gap: 10px;
    }

    .footer-part .service-part {
        margin-left: 0px;
        gap: 10px;
    }

    .footer-part .contact-part {
        margin-left: 0px;
        width: 100%;
        gap: 10px;
    }

    .footer-part .contact-part ul li {
        gap: 12px;
    }
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
    .footer-part-top {
        overflow: hidden;
    }

    .footer-part-top .footer-content h1 {
        font-size: 40px;
    }

    .footer-part-top .footer-content p {
        font-size: 18px;
    }

    .footer-part .footer-container {
        padding: 140px 100px;
    }

    .footer-part .footer-container .contact-btn {
        font-size: 22px;
    }

    .footer-part .footer-first-part p {
        font-size: 14px;
        width: 300px;
    }

    .footer-part .company-part h4,
    .footer-part .service-part h4,
    .footer-part .contact-part h4 {
        font-size: 20px;
    }

    .footer-part .company-part {
        margin-left: 80px;
        gap: 10px;
    }

    .footer-part .service-part {
        margin-left: 10px;
        gap: 10px;
    }

    .footer-part .contact-part {
        margin-left: 0px;
        width: 100%;
        gap: 10px;
    }

    .footer-part .contact-part ul li {
        gap: 12px;
    }
}

@media all and (min-width: 321px) and (max-width: 480px) {
    .footer-part-top .footer-container {
        width: 100%;
        overflow: hidden;
    }

    .footer-part-top .footer-content {
        gap: 10px;
    }

    .footer-part-top .footer-content h1 {
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
    }

    .footer-part-top .footer-content p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        width: 80%;
    }

    .footer-part {
        margin-top: 85px;
    }

    .footer-part .footer-container {
        padding: 90px 20px;
    }

    .footer-part .footer-container .contact-btn {
        padding: 12px 24px;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border: 1.5px solid #F6821F;
        box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.15);
        top: -1.5%;
    }

    .footer-part .footer-first-part {
        gap: 14.11px;
    }

    .footer-part .footer-first-part .logo-body img {
        width: 100%;
        height: 100%;
    }

    .footer-part .footer-first-part p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.24px;
        width: 330px;
    }

    .footer-part .company-part,
    .footer-part .service-part,
    .footer-part .contact-part {
        margin-left: 0px;
    }

    .footer-part .company-part,
    .footer-part .service-part,
    .footer-part .contact-part {
        margin-top: 60px;
        gap: 10px;
    }

    .footer-part .company-part h4,
    .footer-part .service-part h4,
    .footer-part .contact-part h4 {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.45px
    }

    .footer-part .company-part ul,
    .footer-part .service-part ul,
    .footer-part .contact-part ul {
        gap: 16px;
    }

    .footer-part .company-part ul li a,
    .footer-part .service-part ul li a,
    .footer-part .contact-part ul li {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .footer-part .contact-part ul li i {
        font-size: 15px;
    }
}

@media only screen and (min-width: 412px) and (max-width: 992px) {
    .footer-part-top .footer-content {
        gap: 10px;
    }

    .footer-part-top .footer-content h1 {
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
    }

    .footer-part-top .footer-content p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 336px;
        text-align: center;
    }

    .footer-part {
        margin-top: 85px;
    }

    .footer-part .footer-container {
        padding: 90px 20px 58px;
    }

    .footer-part .footer-container .contact-btn {
        padding: 12px 24px;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border: 1.5px solid #F6821F;
        box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.15);
        top: -1.5%;
    }

    .footer-part .footer-first-part {
        gap: 14.11px;
    }

    .footer-part .footer-first-part .logo-body img {
        /* width: 100px;
        height: 28.889px; */
        width: 100%;
        height: 100%;
    }

    .footer-part .footer-first-part p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.24px;
        width: 100%;
    }

    .footer-part .company-part,
    .footer-part .service-part,
    .footer-part .contact-part {
        margin-left: 0px;
    }

    .footer-part .company-part,
    .footer-part .service-part,
    .footer-part .contact-part {
        margin-top: 60px;
        gap: 10px;
    }

    .footer-part .company-part h4,
    .footer-part .service-part h4,
    .footer-part .contact-part h4 {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.45px;
    }

    .footer-part .company-part ul,
    .footer-part .service-part ul,
    .footer-part .contact-part ul {
        gap: 16px;
    }

    .footer-part .company-part ul li a,
    .footer-part .service-part ul li a,
    .footer-part .contact-part ul li {
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .footer-part .contact-part ul li i {
        font-size: 15px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .footer-part-top .footer-container {
        overflow: hidden;
    }

    .footer-part-top .footer-content {
        gap: 12px;
    }

    .footer-part-top .footer-content h1 {
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .footer-part-top .footer-content p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
    }

    .footer-part {
        margin-top: 60px;
    }

    .footer-part .footer-container .contact-btn {
        top: -2.8%;
    }

    .footer-part .footer-container {
        padding: 90px 40px 70px;
    }

    .footer-part .footer-container .row .col-md-6:nth-child(1) {
        order: 1;
    }

    .footer-part .footer-container .row .col-md-6:nth-child(2) {
        order: 3;
    }

    .footer-part .footer-container .row .col-md-6:nth-child(3) {
        order: 4;
    }

    .footer-part .footer-container .row .col-md-6:nth-child(4) {
        order: 2;
    }

    .footer-part .footer-first-part p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0.24px;
    }

    .footer-part .contact-part {
        margin-top: 12px;
    }

    .footer-part .company-part,
    .footer-part .service-part {
        margin-top: 70px;
    }

    .footer-part .company-part h4,
    .footer-part .service-part h4,
    .footer-part .contact-part h4 {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.45px;
    }

    .footer-part .company-part ul li a,
    .footer-part .service-part ul li a,
    .footer-part .contact-part ul li {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}