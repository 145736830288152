* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --josefin-text: 'Josefin Sans', sans-serif;
    --rubik-text: 'Rubik', sans-serif;
    --archivo-text: 'Archivo', sans-serif;
    --orange-color: #F6821F;
    --dark-blue-color: #005777;
    --white-color: #FFF;
    --gray-color: #696969;

}

.service-container {
    margin: auto;
    padding: 0 150px;
}

/* --------------------------------section1 Start */
.S-section-1 {
    background: rgba(0, 87, 119, 0.05);
}

.S-section1-content {
    padding: 225px 0px;
}

.S-section1-content h2 {
    color: var(--dark-blue-color);
    font-family: var(--josefin-text);
    font-size: 50px;
    font-weight: 700;
    line-height: 135.5%;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.S-section1-content p {
    width: 798px;
    color: var(--gray-color);
    font-family: var(--rubik-text);
    font-size: 20px;
    font-weight: 400;
    line-height: 190%;
    letter-spacing: 1px;
    text-transform: capitalize;
}

.S-section1-content button {
    width: 210px;
    height: 50px;
    border-radius: 50px;
    background: var(--orange-color);
    border: none;
    color: var(--white-color);
    font-family: var(--rubik-text);
    font-size: 20px;
    font-weight: 500;
    line-height: 170%;
    margin-top: 80px;
}

.S-section1-img {
    margin-top: 175px;
    margin-bottom: 100px;
}

/* --------------------------------section1 End */
/* --------------------------------section2 Start */
.S-section-2 {
    background-color: var(--white-color);
    padding: 100px 0px;
}

.S-section2-content h4 {
    color: var(--orange-color);
    font-family: var(--rubik-text);
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 15px;
    text-transform: uppercase;
}

.S-section2-content h2 {
    color: var(--dark-blue-color);
    font-family: var(--josefin-text);
    font-size: 50px;
    font-weight: 700;
    line-height: 65px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.S-section2-content p {
    width: 798px;
    padding: 10px 0px;
    color: var(--gray-color);
    font-family: var(--rubik-text);
    font-size: 20px;
    font-weight: 400;
    line-height: 190%;
    letter-spacing: 1px;
    text-transform: capitalize;
}

.S-section-2 .service-box {
    border-radius: 10px;
    background: var(--white-color);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);
    padding: 45px 30px;
    width: 798px;
    margin-bottom: 50px;
    /* height: 300px; */
}

.S-section-2 .service-box h6 {
    color: var(--dark-blue-color);
    font-family: var(--josefin-text);
    font-size: 25px;
    font-weight: 700;
    line-height: 0%;
    letter-spacing: 1.25px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    padding-left: 30px;
}

.S-section-2 .service-box p {
    color: var(--gray-color);
    font-family: var(--rubik-text);
    font-size: 20px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.8px;
    text-transform: capitalize;
    width: 738px;
    padding-top: 20px;
}

/* --------------------------------section2 End */
/* --------------------------------section3 Start */
.S-section-3 {
    background-color: var(--white-color);
    padding: 100px 0px;
}

.S-section-3 .S-section3-title h4,
.S-section-4 .S-section4-title h4 {
    color: var(--orange-color);
    font-family: var(--rubik-text);
    font-size: 30px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 15px;
    text-transform: uppercase;
    text-align: center;
}

.S-section-3 .S-section3-title h2,
.S-section-4 .S-section4-title h2 {
    color: var(--dark-blue-color);
    font-family: var(--josefin-text);
    font-size: 50px;
    font-weight: 700;
    line-height: 65px;
    text-transform: uppercase;
    text-align: center;
}

.S-section-3 .launguage-logo {
    margin-top: 100px !important;
    margin: auto;
}

.S-section-3 .launguage-logo img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    margin: auto;
}

.S-section-3 .launguage-logo h6 {
    padding-top: 26px;
    color: var(--archivo-text);
    text-align: center;
    font-family: var(--rubik-text);
    font-size: 25px;
    font-weight: 400;
    line-height: 20px;
    text-transform: capitalize;
}

/* --------------------------------section3 End */
/* --------------------------------section4 Start */
.S-section-4 {
    background-color: var(--white-color);
    padding: 50px 0px;
}

.S-section-4 .portfolio-card {
    margin-top: 100px;
    position: relative;
}

.S-section-4 .portfolio-card::before {
    position: absolute;
    content: "";
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: 0.4s ease-in-out;
    border-radius: 5px;
    transform: scale(0.1);
}

.S-section-4 .portfolio-card img {
    border-radius: 5px;
}

.S-section-4 .portfolio-card .portfolio-card-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: 0.4s ease-in-out;
}

.S-section-4 .portfolio-card .portfolio-card-content .link-icon {
    font-size: 26px;
    color: var(--white-color);
    margin-bottom: 30px;
}

.S-section-4 .portfolio-card .portfolio-card-content h6 {
    color: var(--white-color);
    font-family: var(--josefin-text);
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.3%;
}

.S-section-4 .portfolio-card .portfolio-card-content h1 {
    color: var(--white-color);
    font-family: var(--rubik-text);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 140.3%;
}

[data-aos="h1-animation"] {
    opacity: 0;
    transform: translateX(120px);
}

[data-aos="h6-animation"] {
    opacity: 0;
    transform: translateX(-120px);
}

[data-aos="link-icon-animation"] {
    opacity: 0;
    transform: scale(0.2);
}

.S-section-4 .portfolio-card:hover::before {
    opacity: 1;
    transform: scale(1);
}

.S-section-4 .portfolio-card:hover .portfolio-card-content {
    opacity: 1;
}

.S-section-4 .portfolio-card:hover [data-aos="h1-animation"].aos-animate,
.S-section-4 .portfolio-card:hover [data-aos="h6-animation"].aos-animate {
    transform: translateX(0);
    opacity: 1;
}

.S-section-4 .portfolio-card:hover [data-aos="link-icon-animation"].aos-animate {
    opacity: 1;
    transform: scale(1);
}

.S-section-4 .portfolio-card .border-animation-1::before {
    content: "";
    position: absolute;
    top: 21px;
    left: 18px;
    width: 0%;
    height: 2px;
    background-color: var(--white-color);
    transition: 0.2s;
}

.S-section-4 .portfolio-card .border-animation-2::before {
    content: "";
    position: absolute;
    top: 21px;
    right: 18px;
    width: 2px;
    height: 0px;
    background-color: var(--white-color);
    transition: 0.2s;
}

.S-section-4 .portfolio-card .border-animation-3::before {
    content: "";
    position: absolute;
    bottom: 21px;
    right: 18px;
    width: 0%;
    height: 2px;
    background-color: var(--white-color);
    transition: 0.2s;
}

.S-section-4 .portfolio-card .border-animation-4::before {
    content: "";
    position: absolute;
    bottom: 21px;
    left: 18px;
    width: 2px;
    height: 0px;
    background-color: var(--white-color);
    transition: 0.2s;
}

.S-section-4 .portfolio-card:hover .border-animation-1::before {
    width: calc(100% - 36px);
    transition-delay: 0s;
}

.S-section-4 .portfolio-card:hover .border-animation-2::before {
    height: calc(100% - 42px);
    transition-delay: 0.2s;
}

.S-section-4 .portfolio-card:hover .border-animation-3::before {
    width: calc(100% - 36px);
    transition-delay: 0.4s;
}

.S-section-4 .portfolio-card:hover .border-animation-4::before {
    height: calc(100% - 42px);
    transition-delay: 0.6s;
}

/* --------------------------------section4 End */

/* --------------------------------medai querry Start */
/* --------------------------------mobile device Start */

@media all and (min-width: 321px) and (max-width: 480px) {
    .service-container {
        padding: 0 20px;
        overflow: hidden;
    }

    .S-section1-content {
        padding: 52px 0;
    }

    .S-section1-content h2 {
        font-size: 20px;
    }

    .S-section1-content p {
        width: 100%;
        font-size: 12px;
    }

    .S-section1-content button {
        margin-top: 30px;
        font-family: 600;
    }

    .S-section1-img img {
        width: 100%;
        margin: auto;
        display: flex;
        justify-content: center;
    }

    .S-section1-img {
        margin-top: 60px;
        margin-bottom: 80px;
        margin: auto;
    }

    .S-section2-content h4 {
        font-size: 15px;
        letter-spacing: 7.5px;
    }

    .S-section-2 {
        padding: 50px 0px;
    }

    /* .S-section-2 .service-container .row .col {
    flex-direction: column;
} */
    .S-section2-content h2 {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.4px;
        margin-top: 10px !important;
    }

    .S-section2-content p {
        width: 100%;
    }

    .S-section-2 .service-box {
        width: 100%;
        margin-top: 20px;
        padding: 18px 12px;
    }

    .S-section-2 .service-box img {
        width: 30px;
    }

    .S-section-2 .service-box h6 {
        font-size: 14px;
        letter-spacing: 0px;
        padding-left: 15px;
        width: 100%;
    }

    .S-section-2 .service-box p {
        width: 100%;
        font-size: 10px;
    }

    .S-section-3 {
        padding: 0px 0;
    }

    .S-section-3 .S-section3-title h4,
    .S-section-4 .S-section4-title h4 {
        font-size: 15px;
        letter-spacing: 7.5px;
        font-weight: 600;
    }

    .S-section-3 .S-section3-title h2,
    .S-section-4 .S-section4-title h2 {
        font-size: 20px;
        width: 100%;
        line-height: 30px;
        margin-top: 10px !important;
    }

    .S-section-3 .launguage-logo {
        margin-top: 30px !important;
    }

    .S-section-3 .launguage-logo img {
        width: 50px;
        height: 50px;
    }

    .S-section-3 .launguage-logo h6 {
        font-size: 12px;
        padding-top: 9px;
    }

    .S-section-4 .portfolio-card {
        margin-top: 30px;
    }

    .S-section-4 .portfolio-card .portfolio-card-content h6,
    .S-section-4 .portfolio-card .portfolio-card-content h1 {
        font-size: 20px;
    }
}
@media  screen and (min-width : 768px) and (max-width : 1024px) {
    .service-container {
        padding: 0px 40px;
        overflow: hidden;
    }.S-section1-content {
        padding: 63px 0px;
    }.S-section1-content h2 {
        font-size: 20px;
        width: 334px;
    }.S-section1-content p {
        width: 100%;
        font-size: 12px;
        margin-top: 20px !important;
    }.S-section1-content button {
        width: 130px;
        height: 35px;
        font-size: 13px;
        margin-top:30px;
    }.S-section1-img {
        margin-top: 68px;
    }.S-section1-img img {
        width: 100%;
    }.S-section-2 {
        padding: 50px 0;
    }.S-section2-content h4 {
        font-size: 15px;
        letter-spacing: 7.5px;
    }.S-section2-content h2 {
        font-size: 20px;
        letter-spacing: 0.4px;
        width: 334px;
        line-height: 30px;
        margin-top:10px !important;
    }.S-section2-content p {
        width: 100%;
        line-height: 150%;
        font-size: 12px;
    }.S-section-2 .service-box {
        width:100%;
    }.S-section-2 .service-box h6 {
        font-size: 12px;
        letter-spacing: 0.1px;
        width: 100%;
    }.S-section-2 .service-box p{
        font-size: 10px;
        letter-spacing: 0.2px;
        width: 100%;
    }.S-section-2 .service-box img {
        width: 25px;
        height: 25px;
    }.S-section-3 {
        padding: 50px 0;
    }.S-section-3 .S-section3-title h4, .S-section-4 .S-section4-title h4 {
        font-size: 15px;
        letter-spacing: 7.5px;
    }.S-section-3 .S-section3-title h2, .S-section-4 .S-section4-title h2 {
        font-size: 20px;
        line-height: 20px;
        margin-top: 10px !important;
    }
    .S-section-3 .launguage-logo {
        margin-top: 50px !important;
        margin: auto;
    }.S-section-3 .launguage-logo img {
        width: 40px;
        height:40px;
    }.S-section-4 .portfolio-card .portfolio-card-content h1{
        font-size: 20px;
        width: 110%;
    }.S-section-4 .portfolio-card .portfolio-card-content h6 {
        font-size: 15px;
    }
}
/* --------------------------------mobile device End */
/* -------------------------------section1-Start---- */
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .service-container {
        padding: 0 100px;
    }

    .S-section1-content h2 {
        width: 620px;
        font-size: 40px;
    }

    .S-section1-content p {
        width: 600px;
    }

    .S-section1-img img {
        width: 550px;
        margin-left: 30px;
    }

    .S-section2-content h2 {
        font-size: 34px;
        width: 550px;
    }

    .S-section2-content p {
        width: 100%;
    }

    .S-section-2 .service-box p {
        width: 100%;
    }

    .S-section-2 .service-box {
        width: 100%;
    }

    .S-section-2 .service-box h6 {
        font-size: 18px;
        letter-spacing: 0px;
        width: 100%;
    }

    .S-section-3 .S-section3-title h2 {
        font-size: 40px;
    }

    .S-section-4 .portfolio-card .portfolio-card-content h1 {
        font-size: 28px;
    }
}

/* --------------------------screen size  1441px TO 1600px Start------- */
@media only screen and (min-width: 1441px) and (max-width: 1600px) {

    /* -------------------------------section1-Start---- */
    .service-container {
        padding: 0 100px;
    }

    .S-section1-content h2 {
        width: 620px;
        font-size: 40px;
    }

    .S-section1-content p {
        width: 600px;
    }

    .S-section1-img img {
        width: 600px;
        margin-left: 30px;
    }

    /* -------------------------------section1-End---- */
    /* -------------------------------section2-Start---- */
    .S-section2-content h2 {
        font-size: 38px;
        width: 550px;
    }

    .S-section2-content p {
        width: 100%;
    }

    .S-section-2 .service-box p {
        width: 100%;
    }

    .S-section-2 .service-box {
        width: 100%;
    }

    .S-section-2 .service-box h6 {
        font-size: 24px;
        letter-spacing: 0px;
        width: 100%;
    }

    /* -------------------------------section2-End---- */

}
@media only screen and (min-width: 1600px) and (max-width: 1880px) {

    /* -------------------------------section1-Start---- */
    .service-container {
        padding: 0 100px;
    }

    .S-section1-content h2 {
        width: 620px;
        font-size: 40px;
    }

    .S-section1-content p {
        width: 600px;
    }

    .S-section1-img img {
        width: 600px;
        margin-left: 30px;
    }

    /* -------------------------------section1-End---- */
    /* -------------------------------section2-Start---- */
    .S-section2-content h2 {
        font-size: 38px;
        width: 550px;
    }

    .S-section2-content p {
        width: 100%;
    }

    .S-section-2 .service-box p {
        width: 100%;
    }

    .S-section-2 .service-box {
        width: 100%;
    }

    .S-section-2 .service-box h6 {
        font-size: 24px;
        letter-spacing: 0px;
        width: 100%;
    }

    /* -------------------------------section2-End---- */

}

/* --------------------------screen size  1441px TO 1600px End------- */
/* -------------------------------section1-End---- */
/* --------------------------------medai querry End */