* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

:root {
    --josefin-text: "Josefin Sans", sans-serif;
    --rubik-text: "Rubik", sans-serif;
    --archivo-text: "Archivo", sans-serif;
    --orange-color: #f6821f;
    --dark-blue-color: #005777;
    --white-color: #fff;
}

.home-container {
    margin: auto;
    padding: 0 150px;
}

/* --------------------------------section1 Start */
.section-1 {
    background: rgba(0, 87, 119, 0.05);
}

.section1-content {
    padding: 225px 0px;
    position: relative;
}

.section1-content h2 {
    color: var(--dark-blue-color);
    font-family: var(--josefin-text);
    font-size: 50px;
    font-weight: 700;
    line-height: 135.5%;
    letter-spacing: 1px;
    text-transform: capitalize;
    width: 923px;
}

.section1-content span {
    position: absolute;
    top: 33.5% !important;
    left: 33% !important;
    width: 100%;
    height: 0px;
    font-size: 45px;
    color: #f6821f;
    text-transform: uppercase;
    font-family: var(--rubik-text);
    font-weight: 600;
}

.Typewriter__cursor {
    margin-left: -24px !important;
    background-color: #005777;
}

.section-5 .slick-slider .icon-left {
    left: -6%;
}

.section-5 .slider-content {
    padding-top: 30px;
}

.section-5 .slider {
    width: 1100px;
    height: 450px;
}

.section-5 .slider-content button {
    margin-top: 50px;
}

.slick-slide img {
    width: 100%;
}

.section1-content p {
    width: 798px;
    color: #696969;
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%;
    letter-spacing: 1px;
    text-transform: capitalize;
}

.section1-content button {
    width: 210px;
    height: 50px;
    border-radius: 50px;
    background: #f6821f;
    border: none;
    color: #fff;
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
    margin-top: 80px;
}

.section-5 .slick-slider .icon-left {
    left: -6%;
}

.section-5 .slider-content {
    padding-top: 30px;
}

.section-5 .slider {
    width: 1100px;
    height: 450px;
}

.section-5 .slider-content button {
    margin-top: 50px;
}

.slick-slide img {
    width: 100%;
}

.section1-img {
    margin-top: 200px;
    margin-bottom: 100px;
}

/* --------------------------------section1 End */
/* --------------------------------section2 Start */
.section-2 {
    background-color: var(--white-color);
    padding: 50px 0px;
    position: relative;
}

.section-2 .years {
    position: absolute;
    top: 15%;
    width: 275.961px;
    height: 113.564px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}

.section-2 .years h4,
.section-2 .client h4,
.section-2 .projects h4,
.section-2 .projects2 h4 {
    color: var(--orange-color);
    font-family: var(--rubik-text);
    font-size: 35px;
    font-weight: 600;
    line-height: 170%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-2 .years h6,
.section-2 .client h6,
.section-2 .projects h6,
.section-2 .projects2 h6 {
    color: var(--dark-blue-color);
    font-family: var(--rubik-text);
    font-size: 20px;
    font-weight: 600;
    line-height: 170%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-2 .client {
    position: absolute;
    top: 12%;
    left: 34%;
    width: 201.009px;
    height: 113.564px;
    border-radius: 20px;
    background: var(--white-color);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}

.section-2 .projects {
    position: absolute;
    bottom: 8%;
    left: 13.6%;
    width: 278.232px;
    height: 113.564px;
    border-radius: 20px;
    background: var(--white-color);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}

.section-2 .projects2 {
    position: absolute;
    bottom: 29%;
    left: 35%;
    width: 260.062px;
    height: 113.564px;
    border-radius: 20px;
    background: var(--white-color);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}

.section2-content {
    margin-left: 23px;
}

.section2-content h6 {
    color: var(--orange-color);
    font-family: var(--rubik-text);
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 15px;
    text-transform: uppercase;
    margin-top: 20px !important;
}

.section2-content h2 {
    color: var(--dark-blue-color);
    font-family: var(--josefin-text);
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 65px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.section2-content p {
    color: #696969;
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%;
    letter-spacing: 1px;
    text-transform: capitalize;
    width: 798px;
}

.section2-content button {
    margin-top: 80px;
    width: 210px;
    height: 50px;
    border-radius: 50px;
    background: var(--orange-color);
    color: var(--white-color);
    font-family: var(--rubik-text);
    font-size: 20px;
    font-weight: 500;
    line-height: 170%;
    border: none;
}

/* --------------------------------section2 End */
/* --------------------------------section3 Start */
.section-3 {
    background: rgba(0, 87, 119, 0.05);
    padding: 120px 0px;
}

.section3-content-title h4,
.section4-content-title h4,
.section-5-content-title h4 {
    color: var(--orange-color);
    font-family: var(--rubik-text);
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 15px;
    text-transform: uppercase;
    text-align: center;
}

.section3-content-title h2,
.section4-content-title h2,
.section-5-content-title h2 {
    color: var(--dark-blue-color);
    text-align: center;
    font-family: var(--josefin-text);
    font-size: 50px;
    font-weight: 700;
    line-height: 65px;
    text-transform: uppercase;
}

.service-menu li {
    list-style: none;
    margin-top: 100px;
    margin-top: 100px !important;
    margin: 0 30px;
    display: flex;
    justify-content: center;
    display: inline-flex;
    align-items: center;
}

.service-menu button {
    border: none;
    width: 203px;
    background-color: transparent;
    color: var(--dark-blue-color);
    text-align: center;
    font-family: var(--rubik-text);
    font-size: 30px;
    font-weight: 500;
    line-height: 100%;
    text-align: center;
    text-transform: capitalize;
    padding-bottom: 10px;
}

.active-li {
    color: var(--orange-color);
}

.service-menu .active-li button {
    color: #f6821f;
    border-bottom: 6px solid #f6821f;
}

.section-3 hr {
    border-width: 2px;
    border-color: #005777;
}

.section3-content {
    margin-top: 100px;
}

.section3-img {
    margin-top: 100px;
}

.section3-content h2 {
    width: 725px;
    color: var(--dark-blue-color);
    font-family: var(--josefin-text);
    font-size: 50px;
    font-weight: 700;
    line-height: 65px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.section3-content p {
    width: 725px;
    color: var(--dark-blue-color);
    font-family: var(--rubik-text);
    font-size: 20px;
    font-weight: 400;
    line-height: 190%;
    letter-spacing: 1px;
    text-transform: capitalize;
}

.language-icon {
    margin-top: 85px;
    display: flex;
    gap: 20px;
}

.language-icon img {
    margin: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.language-icon p {
    margin-top: 20px;
    color: var(--dark-blue-color);
    font-family: var(--rubik-text);
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    padding: 0px 10px;
}

/* --------------------------------section3 End */
/* --------------------------------section4 Start */
.section-4 {
    background-color: var(--white-color);
    padding: 100px 0px;
}

.section-4 .margin-top {
    margin-top: 100px;
}

.section3-content-title,
.section4-content-title,
.section-5-content-title {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.section-4 .developbox {
    position: relative;
}

.section-4 .developbox .line::before {
    position: absolute;
    content: "";
    width: 140px !important;
    top: 23% !important;
    left: 35%;
    border: 2px dashed var(--dark-blue-color);
}

.section-4 .developbox h6 {
    color: var(--dark-blue-color);
    font-family: var(--rubik-text);
    font-size: 25px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 20px !important;
}

.section-4 .developbox p {
    color: #696969;
    font-family: var(--rubik-text);
    font-size: 15px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.6px;
    text-transform: capitalize;
    display: flex;
    width: 296px;
    flex-direction: column;
    justify-content: center;
}

.reveal {
    position: relative;
    opacity: 0;
}

.reveal.active {
    opacity: 1;
}

.section-4 .row .vv:nth-child(1) .active.zoom-animation img {
    animation: zoom 1s;
}

.section-4 .row .vv:nth-child(1) .active.zoom-animation h6,
.section-4 .row .vv:nth-child(1) .active.zoom-animation p {
    animation: fade-up 1s;
}

.section-4 .row .vv:nth-child(1) .active.zoom-animation .line::before {
    animation: fade-left 1.5s;
    animation-delay: 0.8s;
    animation-fill-mode: both;
}

.section-4 .row .vv:nth-child(2) .active.zoom-animation img {
    animation: zoom 1s;
    animation-delay: 1s; animation-fill-mode: both;
}

.section-4 .row .vv:nth-child(2) .active.zoom-animation h6,
.section-4 .row .vv:nth-child(2) .active.zoom-animation p {
    animation: fade-up 1s;
    animation-delay: 2s;
    animation-fill-mode: both;
}

.section-4 .row .vv:nth-child(2) .active.zoom-animation .line::before {
    animation: fade-left 1.5s;
    animation-delay: 2s;
    animation-fill-mode: both;
}

.section-4 .row .vv:nth-child(3) .active.zoom-animation img {
    animation: zoom 1s;
    animation-delay:3s;
    animation-fill-mode: both;
}

.section-4 .row .vv:nth-child(3) .active.zoom-animation h6,
.section-4 .row .vv:nth-child(3) .active.zoom-animation p {
    animation: fade-up 1s;
    animation-delay: 3s;
    animation-fill-mode: both;
}

.section-4 .row .vv:nth-child(3) .active.zoom-animation .line::before {
    animation: fade-left 1.5s;
    animation-delay: 3s;
    animation-fill-mode: both;
}

.section-4 .row .vv:nth-child(4) .active.zoom-animation img {
    animation: zoom 1s;
    animation-delay: 4s;
    animation-fill-mode: both;
}

.section-4 .row .vv:nth-child(4) .active.zoom-animation h6,
.section-4 .row .vv:nth-child(4) .active.zoom-animation p {
    animation: fade-up 1s;
    animation-delay: 4s;
    animation-fill-mode: both;
}

.section-4 .row .vv:nth-child(4) .active.zoom-animation .line::before {
    animation: fade-left 1.5s;
    animation-delay: 4s;
    animation-fill-mode: both;
    display: none;
}

@keyframes zoom {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes fade-up {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes fade-left {
    0% {
        width: 0px;
        opacity: 0;
    }

    100% {
        width: 200px;
        opacity: 1;
    }
}

/* --------------------------------section4 End */
/* --------------------------------section5 Start */
.section-5 {
    background: rgba(0, 87, 119, 0.05);
    padding: 74px 0px;
}

.section-5 .slider {
    background-color: var(--white-color);
    padding: 24px 21px;
    width: 1346px;
    height: 658px;
    margin-top: 100px !important;
    margin: auto;
}

.section-5 .slider-content {
    padding-top: 118px;
}

.section-5 .slider-content h2 {
    color: #005777;
    font-family: var(--josefin-text);
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 135.5%;
    letter-spacing: 1px;
    text-transform: capitalize;
}

.section-5 .slider-content p {
    color: #696969;
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%;
    letter-spacing: 1px;
    text-transform: capitalize;
    width: 601px;
}

.section-5 .slider-content button {
    margin-top: 88px;
    width: 210px;
    height: 50px;
    border-radius: 50px;
    background: #f6821f;
    color: #fff;
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
    border: none;
}

.section-5 .slider-img {
    margin-left: 20px;
}

.section-5 .slick-slider .icon-left {
    position: absolute;
    left: -10%;
    top: 50%;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.section-5 .icon-left,
.section-5 .icon-right {
    font-size: 35px;
    position: absolute;
    top: 50%;
    color: var(--dark-blue-color);
    transition: 0.3s;
    cursor: pointer;
}

.section-5 .icon-left {
    left: -11%;
}

.section-5 .icon-right {
    right: -10%;
}

.section-5 .icon-left:hover,
.section-5 .icon-right:hover {
    color: var(--orange-color);
}

/* --------------------------------section5 End */
/* --------------------------------section6 Start */
.section-6 {
    padding: 100px 0px;
}

/* --------------------------------section6 End */

/* ---------------------------------medai queery Start----------------------- */
/* ---------------------------------mobile device Start---------------------- */
/* ----------------one plus Start ---------------- */
@media only screen and (min-width: 412px) and (max-width: 892px) {
    .home-container {
        padding: 0 20px;
    }

    .section1-content {
        padding: 40px 0px;
    }

    .section1-content h2 {
        font-size: 20px;
        width: 350px;
        letter-spacing: 0.4px;
    }

    .section1-content span {
        font-size: 18px;
        top: 26% !important;
        left: 21% !important;
        position: absolute;
    }

    .Typewriter__cursor {
        margin-left: -14px !important;
    }

    .section1-content button {
        margin-top: 30px;
    }

    .section1-content p {
        width: 100% !important;
        font-size: 12px;
    }

    .section1-img {
        margin-top: 20px;
        margin: auto !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .section1-img img {
        width: 100%;
    }

    .section-2 .home-container .row {
        flex-direction: column-reverse !important;
    }

    .section2-content {
        margin-left: 0px;
    }

    .section2-content h6 {
        font-size: 15px;
        text-align: left;
    }

    .section2-content h2 {
        width: 100%;
        font-size: 20px;
        line-height: 30px;
    }

    .section2-content p {
        width: 100%;
        font-size: 12px;
    }

    .section2-content button {
        margin-top: 30px;
    }

    .section2-img img {
        width: 100%;
        margin-top: 50px;
    }

    .section-2 .years h4,
    .section-2 .client h4,
    .section-2 .projects h4,
    .section-2 .projects2 h4 {
        font-size: 15px;
    }

    .section-2 .years h6,
    .section-2 .client h6,
    .section-2 .projects h6,
    .section-2 .projects2 h6 {
        font-size: 10px;
    }

    .section-2 .years {
        position: absolute;
        top: 60%;
        left: 10%;
        width: 120px;
        height: 49px;
        border-radius: 10px;
        padding: 4px 10px;
    }

    .section-2 .client {
        position: absolute;
        top: 60%;
        left: 70%;
        width: 120px;
        padding: 4px 10px;
        height: 49px;
        border-radius: 10px;
    }

    .section-2 .projects {
        position: absolute;
        bottom: 8%;
        left: 13.6%;
        width: 120px;
        height: 49px;
        padding: 4px 10px;
        border-radius: 10px;
    }

    .section-2 .projects2 {
        position: absolute;
        bottom: 15%;
        left: 63%;
        width: 120px;
        padding: 4px 10px;
        height: 49px;
        border-radius: 10px;
    }

    .section3-content-title h4,
    .section4-content-title h4,
    .section-5-content-title h4 {
        font-size: 15px;
        letter-spacing: 7.5px;
        font-weight: 600;
    }

    .section3-content-title h2,
    .section4-content-title h2,
    .section-5-content-title h2 {
        font-size: 20px;
        width: 300px;
        line-height: 30px;
    }

    .section-3 .home-container .categery {
        display: none;
    }

    .service-menu li {
        margin-top: 30px !important;
        margin: 0px 10px;
    }

    .service-menu button {
        width: 79px;
        font-size: 12px;
    }

    .developbox .line {
        display: none;
    }

    .section-4 .margin-top {
        margin-top: 50px;
    }

    .section-4 .developbox img {
        justify-content: center;
        display: flex;
        margin: auto;
        margin-top: 50px;
    }

    .section-4 .developbox h6 {
        text-align: center;
        margin: auto;
        width: 100%;
    }

    .section-4 .developbox p {
        text-align: center;
        margin: auto !important;
    }

    .section-3 .icon-left {
        position: absolute;
        top: 60%;
        left: -1%;
    }

    .section-3 .icon-right {
        position: absolute;
        right: 1%;
        bottom: 20%;
    }

    .section3-content {
        margin-top: 40px;
    }

    .section3-content h2 {
        width: 100%;
        font-size: 18px;
        letter-spacing: 0.4px;
        line-height: 26px;
    }

    .section-3 .home-container .second-row {
        flex-direction: column-reverse;
    }

    .section3-img {
        margin-top: 50px;
    }

    .section3-img img {
        width: 100%;
    }

    .section3-content p {
        width: 100%;
        text-align: center;
    }

    .language-icon {
        justify-content: center;
    }

    .section-5 .slider {
        margin-top: 20px !important;
    }

    .section-5 .slider {
        width: 100%;
        height: auto;
    }

    .section-5 .slider .rev {
        flex-direction: column-reverse;
    }

    .section-5 .slider-content p {
        width: 100%;
        font-size: 12px;
    }

    .section-5 .row .slider .slider-img {
        margin: auto;
        justify-content: center;
        display: flex;
    }

    .section-5 .slider {
        padding: 21px 18px;
    }

    .section-5 .slick-slide img {
        width: 100%;
    }

    /* html:not(.no-js) [data-aos^=zoom][data-aos^=zoom].aos-animate {
            margin: auto;
        } */
    .section-5 .slider-img {
        margin-left: 0px;
    }

    .section-5 .slider-content h2 {
        font-size: 20px;
        width: 100%;
    }

    .section-5 .slider-content {
        padding-top: 20px;
    }

    .section-5 .slider-content button {
        margin: auto;
        margin-top: 20px;
        width: 130px;
        height: 35px;
        display: flex;
        justify-content: center;
    }

    .section-5 .slick-slider .icon-left,
    .section-5 .slick-slider .icon-right {
        font-size: 24px;
        margin: auto;
    }
}

/* ----------------one plus End ---------------- */

@media all and (min-width: 321px) and (max-width: 480px) {
    .home-container {
        padding: 0 20px;
    }

    .section1-content {
        padding: 40px 0px;
    }

    .section1-content h2 {
        font-size: 19px;
        width: 340px !important;
        letter-spacing: 0.4px;
    }

    .section1-content span {
        font-size: 18px;
        top: 20% !important;
        left: 25% !important;
        position: absolute;
    }

    .Typewriter__cursor {
        margin-left: -14px !important;
    }

    .section1-content button {
        margin-top: 30px;
    }

    .section1-content p {
        width: 100% !important;
        font-size: 12px;
    }

    .section1-img {
        margin-top: 20px;
        margin: auto !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .section1-img img {
        width: 100%;
    }

    .section-2 .home-container .row {
        flex-direction: column-reverse;
    }

    .section2-content {
        margin-left: 0px;
    }

    .section2-content h6 {
        font-size: 15px;
        text-align: left;
    }

    .section2-content h2 {
        width: 100%;
        font-size: 20px;
        line-height: 30px;
    }

    .section2-content p {
        width: 100%;
        font-size: 12px;
    }

    .section2-content button {
        margin-top: 30px;
    }

    .section2-img img {
        width: 100%;
        margin-top: 50px;
    }

    .section-2 .years h4,
    .section-2 .client h4,
    .section-2 .projects h4,
    .section-2 .projects2 h4 {
        font-size: 15px;
    }

    .section-2 .years h6,
    .section-2 .client h6,
    .section-2 .projects h6,
    .section-2 .projects2 h6 {
        font-size: 10px;
    }

    .section-2 .years {
        position: absolute;
        top: 60%;
        left: 10%;
        width: 120px;
        height: 49px;
        border-radius: 10px;
        padding: 4px 10px;
    }

    .section-2 .client {
        position: absolute;
        top: 60%;
        left: 70%;
        width: 120px;
        padding: 4px 10px;
        height: 49px;
        border-radius: 10px;
    }

    .section-2 .projects {
        position: absolute;
        bottom: 8%;
        left: 13.6%;
        width: 120px;
        height: 49px;
        padding: 4px 10px;
        border-radius: 10px;
    }

    .section-2 .projects2 {
        position: absolute;
        bottom: 15%;
        left: 63%;
        width: 120px;
        padding: 4px 10px;
        height: 49px;
        border-radius: 10px;
    }

    .section3-content-title h4,
    .section4-content-title h4,
    .section-5-content-title h4 {
        font-size: 15px;
        letter-spacing: 7.5px;
        font-weight: 600;
    }

    .section3-content-title h2,
    .section4-content-title h2,
    .section-5-content-title h2 {
        font-size: 20px;
        width: 300px;
        line-height: 30px;
    }

    .section-3 .home-container .categery {
        display: none;
    }

    .service-menu li {
        margin-top: 30px !important;
        margin: 0px 10px;
    }

    .service-menu button {
        width: 79px;
        font-size: 12px;
    }

    .developbox .line {
        display: none;
    }
    .section-4 .row .vv:nth-child(1) .active.zoom-animation img {
        animation: zoom 1s;
    }
    
    .section-4 .row .vv:nth-child(1) .active.zoom-animation h6,
    .section-4 .row .vv:nth-child(1) .active.zoom-animation p {
        animation: fade-up 1s;
    }
    
   
    
    .section-4 .row .vv:nth-child(2) .active.zoom-animation img {
        animation: zoom 1s;
        animation-delay: 1s;
        animation-fill-mode: both;
    }
    
    .section-4 .row .vv:nth-child(2) .active.zoom-animation h6,
    .section-4 .row .vv:nth-child(2) .active.zoom-animation p {
        animation: fade-up 1s;
        animation-delay: 1s;
        animation-fill-mode: both;
    }
    
    .section-4 .row .vv:nth-child(2) .active.zoom-animation .line::before {
        animation: fade-left 1.5s;
        animation-delay: 1s;
        animation-fill-mode: both;
    }
    
    .section-4 .row .vv:nth-child(3) .active.zoom-animation img {
        animation: zoom 1s;
        animation-delay:1s;
        animation-fill-mode: both;
    }
    
    .section-4 .row .vv:nth-child(3) .active.zoom-animation h6,
    .section-4 .row .vv:nth-child(3) .active.zoom-animation p {
        animation: fade-up 1s;
        animation-delay: 1s;
        animation-fill-mode: both;
    }
    
    .section-4 .row .vv:nth-child(3) .active.zoom-animation .line::before {
        animation: fade-left 1.5s;
        animation-delay: 1s;
        animation-fill-mode: both;
    }
    
    .section-4 .row .vv:nth-child(4) .active.zoom-animation img {
        animation: zoom 1s;
        animation-delay: 1s;
        animation-fill-mode: both;
    }
    
    .section-4 .row .vv:nth-child(4) .active.zoom-animation h6,
    .section-4 .row .vv:nth-child(4) .active.zoom-animation p {
        animation: fade-up 1s;
        animation-delay: 1s;
        animation-fill-mode: both;
    }
    
    .section-4 .row .vv:nth-child(4) .active.zoom-animation .line::before {
        animation: fade-left 1.5s;
        animation-delay:1s;
        animation-fill-mode: both;
        display: none;
    }
    .section-4 .margin-top {
        margin-top: 50px;
    }

    .section-4 .developbox img {
        justify-content: center;
        display: flex;
        margin: auto;
        margin-top: 50px;
    }

    .section-4 .developbox h6 {
        text-align: center;
        margin: auto;
        width: 100%;
    }

    .section-4 .developbox p {
        text-align: center;
        margin: auto !important;
    }

    .section-3 .icon-left {
        position: absolute;
        top: 60%;
        left: -1%;
    }

    .section-3 .icon-right {
        position: absolute;
        right: 1%;
        bottom: 20%;
    }

    .section3-content {
        margin-top: 40px;
    }

    .section3-content h2 {
        width: 100%;
        font-size: 18px;
        letter-spacing: 0.4px;
        line-height: 26px;
    }

    .section-3 .home-container .second-row {
        flex-direction: column-reverse;
    }

    .section3-img {
        margin-top: 50px;
    }

    .section3-img img {
        width: 100%;
    }

    .section3-content p {
        width: 100%;
        font-size: 12px;
        text-align: center;
        line-height: 150%;
    }
    
    .language-icon {
       
        flex-wrap: wrap;
        justify-content: center !important;
    }
    .language-icon img {
        display: flex;
        flex-direction:column;
    }
    .language-icon p {
        padding: 0 0px;
    }
    .section-5 .slider {
        margin-top: 20px !important;
    }

    .section-5 .slider {
        width: 100%;
        height: auto;
    }

    .section-5 .slider .rev {
        flex-direction: column-reverse;
    }

    .section-5 .slider-content p {
        width: 100%;
        font-size: 12px;
    }

    .section-5 .row .slider .slider-img {
        margin: auto;
        justify-content: center;
        display: flex;
    }

    .section-5 .slider {
        margin-top: 20px !important;
    }

    .section-5 .slider {
        padding: 21px 18px;
    }

    .section-5 .slick-slide img {
        width: 100%;
    }

    /* html:not(.no-js) [data-aos^=zoom][data-aos^=zoom].aos-animate {
        margin: auto;
    } */
    .section-5 .slider-img {
        margin-left: 0px;
    }

    .section-5 .slider {
        padding: 21px 18px;
    }

    .section-5 .slick-slide img {
        width: 100%;
    }

    /* html:not(.no-js) [data-aos^=zoom][data-aos^=zoom].aos-animate {
        margin: auto;
    } */
    .section-5 .slider-img {
        margin-left: 0px;
    }

    .section-5 .slider-content h2 {
        font-size: 20px;
        width: 100%;
    }

    .section-5 .slider-content {
        padding-top: 20px;
    }

    .section-5 .slider-content button {
        margin: auto;
        margin-top: 20px;
        width: 130px;
        height: 35px;
        display: flex;
        justify-content: center;
    }

    .section-5 .slick-slider .icon-left,
    .section-5 .slick-slider .icon-right {
        font-size: 24px;
        margin: auto;
    }
}

/* ----------------one plus End ---------------- */
@media all and (min-width: 321px) and (max-width: 480px) {
    .home-container {
        padding: 0 20px;
        overflow: hidden;
    }

    .section1-content {
        padding: 40px 0px;
    }

    .section1-content h2 {
        font-size: 19px;
        letter-spacing: 0.4px;
        width: 100%;
    }

    .section1-content span {
        font-size: 16px;
        top: 19.6% !important;
        left: 32.2% !important;
        position: absolute;
    }

    .Typewriter__wrapper {
        width: auto !important;
    }

    .Typewriter__cursor {
        margin-left: -12px !important;
    }

    .section1-content button {
        margin-top: 30px;
    }

    .section1-content p {
        width: 100%;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.36px;
    }

    .section1-img {
        margin-top: 20px;
        margin: auto !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .section1-img img {
        width: 100%;
    }

    .section-2 .home-container .row {
        flex-direction: column-reverse;
    }

    .section2-content {
        margin-left: 0px;
    }

    .section2-content h6 {
        font-size: 15px;
        text-align: left;
    }

    .section2-content h2 {
        width: 100%;
        font-size: 20px;
        line-height: 30px;
    }

    .section2-content p {
        width: 100%;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.36px;
    }

    .section2-content button {
        margin-top: 30px;
    }

    .section2-img img {
        width: 100%;
        margin-top: 50px;
    }

    .section-2 .years h4,
    .section-2 .client h4,
    .section-2 .projects h4,
    .section-2 .projects2 h4 {
        font-size: 15px;
    }

    .section-2 .years h6,
    .section-2 .client h6,
    .section-2 .projects h6,
    .section-2 .projects2 h6 {
        font-size: 10px;
    }

    .section-2 .years {
        position: absolute;
        top: 65.5%;
        width: 120px;
        height: 49px;
        border-radius: 10px;
        padding: 4px 10px;
    }

    .section-2 .client {
        position: absolute;
        top: 65%;
        left: 60%;
        width: 120px;
        padding: 4px 10px;
        height: 49px;
        border-radius: 10px;
    }

    .section-2 .projects {
        position: absolute;
        bottom: 8%;
        left: 13.6%;
        width: 120px;
        height: 49px;
        padding: 4px 10px;
        border-radius: 10px;
    }

    .section-2 .projects2 {
        position: absolute;
        bottom: 15%;
        left: 63%;
        width: 120px;
        padding: 4px 10px;
        height: 49px;
        border-radius: 10px;
    }

    .section-3 {
        background: rgba(0, 87, 119, 0.05);
        padding: 55px 0px;
    }

    .section3-content-title h4,
    .section4-content-title h4,
    .section-5-content-title h4 {
        font-size: 15px;
        letter-spacing: 7.5px;
        font-weight: 600;
    }

    .section3-content-title h2,
    .section4-content-title h2,
    .section-5-content-title h2 {
        font-size: 20px;
        width: 300px;
        line-height: 30px;
    }

    .section-3 .home-container .categery {
        display: none;
    }

    .service-menu li {
        margin-top: 30px !important;
        margin: 0px 10px;
    }

    .service-menu button {
        width: 79px;
        font-size: 12px;
    }

    .service-menu .active-li button {
        border-bottom: 3px solid var(--orange-color);
    }

    .developbox .line {
        display: none;
    }

    .section-4 .margin-top {
        margin-top: 0px;
    }

    .section-4 .developbox img {
        justify-content: center;
        display: flex;
        margin: auto;
        margin-top: 50px;
    }

    .section-4 .developbox h6 {
        text-align: center;
        margin: auto;
        width: 100%;
    }

    .section-4 .developbox p {
        text-align: center;
        margin: auto !important;
    }

    .section-3 .icon-left {
        position: absolute;
        top: 60%;
        left: -1%;
    }

    .section-3 .icon-right {
        position: absolute;
        right: 1%;
        bottom: 20%;
    }

    .section3-content {
        margin-top: 40px;
    }

    .section3-content h2 {
        width: 100%;
        font-size: 18px;
        letter-spacing: 0.4px;
        line-height: 26px;
        text-align: center;
    }

    .section-3 .home-container .second-row {
        flex-direction: column-reverse;
    }

    .section3-img {
        margin-top: 50px;
    }

    .section3-img img {
        width: 100%;
    }

    .section3-content p {
        width: 100%;
        text-align: center;
    }

    .language-icon {
        justify-content: center;
    }

    .section-5 .slider {
        width: 100%;
        height: auto;
    }

    .section-5 .slider .rev {
        flex-direction: column-reverse;
    }

    .section-5 .slider-content p {
        width: 100%;
        font-size: 12px;
    }

    .section-5 .row .slider .slider-img {
        margin: auto;
        justify-content: center;
        display: flex;
    }

    .section-5 .slider {
        margin-top: 20px !important;
    }

    .section-5 .slider {
        padding: 21px 18px;
    }

    .section-5 .slick-slide img {
        width: 100%;
    }

    /* html:not(.no-js) [data-aos^=zoom][data-aos^=zoom].aos-animate {
        margin: auto;
    } */

    .section-5 .slider-img {
        margin-left: 0px;
    }

    .section-5 .slider-content h2 {
        font-size: 20px;
        width: 100%;
    }

    .section-5 .slider-content {
        padding-top: 20px;
    }

    .section-5 .slider-content button {
        margin: auto;
        margin-top: 20px;
        width: 130px;
        height: 35px;
        display: flex;
        justify-content: center;
    }

    .section-5 .slick-slider .icon-left,
    .section-5 .slick-slider .icon-right {
        font-size: 24px;
        margin: auto;
    }
}

/* ---------------------------------mobile device End---------------------- */
/* ---------------------------------Tablet device Start-------------------- */
@media screen and (min-width: 768px) and (max-width: 1199.98px) {
    .home-container {
        margin: auto;
        padding: 0 40px;
        overflow: hidden;
    }

    .section1-content {
        padding: 53px 0px;
    }

    .section1-content h2 {
        font-size: 20px;
        width: 334px !important;
    }

    .section1-content span {
        font-size: 20px;
        top: 19% !important;
        left: 62% !important;
    }

    .section1-img {
        margin-top: 60px !important;
    }

    .section1-img img {
        width: 100%;
    }

    .section1-content p {
        width: 100%;
    }

    .section-2 .home-container .row {
        flex-direction: row !important;
    }

    .section2-img img {
        width: 100%;
    }

    .section2-content h2 {
        width: 100%;
        font-size: 20px;
        line-height: 30px;
    }

    .section2-content button {
        width: 130px;
        height: 35px;
        font-size: 13px;
    }

    .section2-content p {
        width: 100%;
        font-size: 12px;
    }

    .section-2 .years {
        top: 27%;
        left: 5%;
    }

    .section-2 .client {
        left: 35%;
        top: 25%;
    }

    .section-2 .projects {
        bottom: 24%;
    }

    .section-2 .projects2 {
        bottom: 39%;
        left: 34%;
    }

    .section-3 .home-container ul {
        margin: auto;
    }

    .section3-content-title h2,
    .section4-content-title h2,
    .section-5-content-title h2 {
        width: 100%;
        font-size: 20px;
        line-height: 20px;
    }

    .section-3 .home-container .categery {
        display: block;
        margin: auto !important;
    }

    .section3-img img {
        width: 100%;
    }

    .section-3 .home-container .second-row {
        flex-direction: row;
    }

    .section3-content h2 {
        font-size: 20px;
    }

    .section3-content p {
        font-size: 12px;
        width: 100%;
        text-align: left;
    }

    .language-icon {
        justify-content: flex-start;
    }

    .section-4 .developbox img {
        width: 40px;
        height: 40px;
        margin: 0px;
    }

    .section-4 .developbox h6 {
        font-size: 15px;
        text-align: left;
    }

    .section-4 .developbox .line::before {
        top: 12%;
        width: 100px !important;
    }

    .section-4 .developbox p {
        width: 100%;
        height: auto;
        text-align: left;
        font-size: 10px;
        line-height: 120%;
        margin-top: 8px !important;
    }

    .developbox .line {
        display: block;
    }

    .section-5 .slider .rev {
        flex-direction: row;
    }

    .section-5 .slider {
        background-color: var(--white-color);
        padding: 24px 21px;
        width: 650px !important;
        margin-top: 100px !important;
        margin: auto;
    }

    .section-5 .slider-content button {
        margin: 30px 0px;
    }
}

@media only screen and (min-width: 898px) and (max-width: 1199px) {
    .home-container {
        margin: auto;
        padding: 0 40px;
        overflow: hidden;
    }

    .section1-content span {
        font-size: 20px;
        top: 15% !important;
        left: 55% !important;
    }

    .section-2 .years {
        top: 19%;
        left: 3%;
        width: 200px;
        height: 45px;
        border-radius: 5px;
    }

    .section-2 .client {
        left: 33%;
        top: 17%;
        border-radius: 5px;
        width: 170px;
        height: 45px;
    }

    .section-2 .projects {
        bottom: 21%;
        left: 5%;
        width: 200px;
        height: 45px;
        border-radius: 5px;
    }

    .section-2 .projects2 {
        bottom: 42%;
        left: 34%;
        width: 180px;
        height: 45px;
        border-radius: 5px;
    }

    .section-2 .years h4,
    .section-2 .client h4,
    .section-2 .projects h4,
    .section-2 .projects2 h4 {
        font-size: 12px;
    }

    .section-2 .years h6,
    .section-2 .client h6,
    .section-2 .projects h6,
    .section-2 .projects2 h6 {
        font-size: 8px;
    }

    .service-menu li {
        margin: 0px 20px;
    }

    .service-menu button {
        font-size: 12px;
        width: 100%;
    }

    .section-5 .slider {
        width: 100% !important;
        background-color: rgb(80, 153, 51);  
      background-color: var(--white-color);
        padding: 41px 18px;
        width: 850px !important;
        margin-top: 100px !important;
        margin: auto;
        height: auto;
    }

    .section-5 .slider-content p {
        width: 100% !important;
        font-size: 12px;
    }

    .section-5 .slider-content {
        padding: 0px;
    }
}

/* ---------------------------------Tablet device End-------------------- */
/* ---------------------------------medai queery section1 start ------------- */
@media only screen and (min-width: 1201px) and (max-width: 1440px) {
    .home-container {
        margin: auto;
        padding: 0 100px;
    }
    .Typewriter__cursor {
        margin-left: -14px !important;
    }
    .section1-content h2 {
        width: 680px;
        font-size: 40px;
    }

    .section1-content span {
        font-size: 40px;
        top:29% !important;
        left:40% !important;
        width: 110%;
    }

    .section1-content span::before {
        width: 100%;
    }

    .section1-content p {
        width: 100%;
        
    }

    .section1-img img {
        width: 580px;
        height: 100%;
    }

    /* ---------------------------------medai queery section1 End ------------- */

    /* ---------------------------------medai queery section2 start ------------- */

    .section2-img img {
        width: 550px;
        /* position: absolute; */
        left: 1%;
    }

    .section2-content {
        margin-left: 30px;
    }

    .section2-content h2 {
        font-size: 30px;
        width: 100%;
        line-height: 50px;
    }

    .section2-content p {
        width: 100%;
    }

    .section-2 .years {
        position: absolute;
        top: 6.5%;
        width: 200.961px;
        height: 113.564px;
    }

    .section-2 .client {
        position: absolute;
        top: 5%;
        left: 38%;
        width: 170.009px;
    }

    .section-2 .projects {
        position: absolute;
        bottom: 52%;
        left: 16%;
        width: 208.232px;
        height: 113.564px;
        border-radius: 20px;
        background: var(--white-color);
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    }

    .section-2 .projects2 {
        position: absolute;
        bottom:65%;
        left: 38%;
        width: 180.062px;
        height: 113.564px;
        border-radius: 20px;
        background: var(--white-color);
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    }

    /* ---------------------------------medai queery section2 End ------------- */
    /* ---------------------------------medai queery section3 Start ------------- */
    .section3-content-title h2 {
        font-size: 45px;
    }

    .section-3 ul {
        display: flex;
        justify-content: center;
    }

    .service-menu li {
        margin: 0 20px;
    }

    .service-menu button {
        width: 160px;
        font-size: 24px;
    }

    .section3-content h2 {
        width: 100%;
        font-size: 32px;
    }

    .section3-content p {
        width: 100%;
    }

    .section3-img img {
        width: 560px;
    }

    /* ---------------------------------medai queery section3 End ------------- */
    /* ---------------------------------medai queery section4 Start ------------- */
    .section-4 .developbox p {
        width: 100%;
        height: auto;
    }

    .section-4 .developbox .line::before {
        content: "";
        width: 10px;
        /* top: 25% !important; */
        left: 49%;
    }

    /* ---------------------------------medai queery section4 End ------------- */
    /* ---------------------------------medai queery section5 Start ------------- */
    .section-5 .slick-slider .icon-left {
        left: -6%;
    }

    .section-5 .slick-slider .icon-left {
        position: absolute;
        left: -10%;
        top: 50%;
        cursor: pointer;
        transition: 0.2s ease-in-out;
    }

    .section-5 .slider-content {
        padding-top: 30px;
    }

    .section-5 .slider {
        width: 100%;
        height: 450px;
    }

    .section-5 .slider-content button {
        margin-top: 50px;
    }

    .slick-slide img {
        width: 100%;
        height: 100%;
    }

    /* ---------------------------------medai queery section5 End ------------- */
}

/* --------------------------screen size  1441px TO 1600px Start------- */
@media only screen and (min-width: 1440px) and (max-width: 1600px) {

    /*-----------------------------------section1 Start------------------------------- */
    .home-container {
        margin: auto;
        padding: 0 100px;
    }

    .section1-content h2 {
        width: 680px;
        font-size: 40px;
    }

    .section1-content span {
        font-size: 40px;
        top: 31.2% !important;
        left: 36% !important;
        width: 110%;
    }

    .section1-content p {
        width: 700px;
    }

    .section1-img img {
        width: 600px;
        height: 100%;
    }

    /*-----------------------------------section1 End------------------------------- */
    /*-----------------------------------section2 Start------------------------------- */
    .section2-img img {
        width: 600px;
    }

    .section2-content {
        margin-left: 30px;
    }

    .section2-content h2 {
        font-size: 36px;
        width: 100%;
        line-height: 50px;
    }

    .section2-content p {
        width: 100%;
    }

    .section-2 .years {
        position: absolute;
        top: 8.5%;
        width: 230.961px;
        height: 113.564px;
    }

    .section-2 .client {
        position: absolute;
        top: 5.7%;
        left: 35%;
        width: 200.009px;
    }

    .section-2 .projects {
        position: absolute;
        bottom: 30%;
        left: 13.6%;
        width: 250.232px;
        height: 113.564px;
    }

    .section-2 .projects2 {
        position: absolute;
        bottom: 43%;
        left: 35%;
        width: 240.062px;
        height: 113.564px;
    }

    /*-----------------------------------section2 End------------------------------- */
    /*-----------------------------------section3 Start------------------------------- */
    .section3-content-title h2 {
        font-size: 45px;
    }

    .section-3 ul {
        display: flex;
        justify-content: center;
    }

    .service-menu li {
        margin: 0 20px;
    }

    .service-menu button {
        width: 160px;
        font-size: 24px;
    }

    .section3-content h2 {
        width: 100%;
        font-size: 40px;
    }

    .section3-content p {
        width: 100%;
    }

    .section3-img img {
        width: 560px;
    }

    /*-----------------------------------section3 End------------------------------- */
    /*-----------------------------------section4 Start------------------------------- */
    .section-4 .developbox p {
        width: 100%;
        height: auto;
    }

    .section-4 .developbox .line::before {
        content: "";
        width: 10px;
        top: 25%;
        left: 40%;
    }

    /*-----------------------------------section4 End------------------------------- */
    /*-----------------------------------section5 Start------------------------------- */
    .section-5 .slick-slider .icon-left {
        left: -6%;
    }

    .section-5 .slick-slider .icon-left {
        position: absolute;
        left: -10%;
        top: 50%;
        cursor: pointer;
        transition: 0.2s ease-in-out;
    }

    .section-5 .slider-content {
        padding-top: 30px;
    }

    .section-5 .slider {
        width: 100%;
        height: 100%;
    }

    .section-5 .slider-content button {
        margin-top: 50px;
    }

    .slick-slide img {
        width: 100%;
        height: 100%;
    }

    /*-----------------------------------section5 End------------------------------- */
}


/* --------------------------screen size  1441px TO 1600px End------- */
@media only screen and (min-width: 1600px) and (max-width: 1880px) {

    /*-----------------------------------section1 Start------------------------------- */
    .home-container {
        margin: auto;
        padding: 0 100px;
    }

    .section1-content h2 {
        width: 680px;
        font-size: 40px;
    }

    .section1-content span {
        font-size: 40px;
        top: 31.2% !important;
        left: 30% !important;
        width: 110%;
    }

    .section1-content p {
        width: 700px;
    }

    .section1-img img {
        width: 100%;
        height: 100%;
    }

    /*-----------------------------------section1 End------------------------------- */
    /*-----------------------------------section2 Start------------------------------- */
    .section2-img img {
        width: 600px;
    }

    .section2-content {
        margin-left: 30px;
    }

    .section2-content h2 {
        font-size: 36px;
        width: 100%;
        line-height: 50px;
    }

    .section2-content p {
        width: 100%;
    }

    .section-2 .years {
        position: absolute;
        top: 8.5%;
        width: 230.961px;
        height: 113.564px;
    }

    .section-2 .client {
        position: absolute;
        top: 5.7%;
        left: 35%;
        width: 200.009px;
    }

    .section-2 .projects {
        position: absolute;
        bottom: 30%;
        left: 13.6%;
        width: 250.232px;
        height: 113.564px;
    }

    .section-2 .projects2 {
        position: absolute;
        bottom: 43%;
        left: 35%;
        width: 240.062px;
        height: 113.564px;
    }

    /*-----------------------------------section2 End------------------------------- */
    /*-----------------------------------section3 Start------------------------------- */
    .section3-content-title h2 {
        font-size: 45px;
    }

    .section-3 ul {
        display: flex;
        justify-content: center;
    }

    .service-menu li {
        margin: 0 20px;
    }

    .service-menu button {
        width: 160px;
        font-size: 24px;
    }

    .section3-content h2 {
        width: 100%;
        font-size: 40px;
    }

    .section3-content p {
        width: 100%;
    }

    .section3-img img {
        width: 560px;
    }

    /*-----------------------------------section3 End------------------------------- */
    /*-----------------------------------section4 Start------------------------------- */
    .section-4 .developbox p {
        width: 100%;
        height: auto;
    }

    .section-4 .developbox .line::before {
        content: "";
        width: 10px;
        top: 25%;
        left: 40%;
    }

    /*-----------------------------------section4 End------------------------------- */
    /*-----------------------------------section5 Start------------------------------- */
    .section-5 .slick-slider .icon-left {
        left: -6%;
    }

    .section-5 .slick-slider .icon-left {
        position: absolute;
        left: -10%;
        top: 50%;
        cursor: pointer;
        transition: 0.2s ease-in-out;
    }

    .section-5 .slider-content {
        padding-top: 30px;
    }

    .section-5 .slider {
        width:90%;
    }

    .section-5 .slider-content button {
        margin-top: 50px;
    }

    .slick-slide img {
        width: 100%;
        height: 100%;
    }

    /*-----------------------------------section5 End------------------------------- */
}
/* ---------------------------------medai queery End----------------------- */