* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --josefin-text: 'Josefin Sans', sans-serif;
    --rubik-text: 'Rubik', sans-serif;
    --archivo-text: 'Archivo', sans-serif;
    --orange-color: #F6821F;
    --dark-blue-color: #005777;
    --white-color: #FFF;
}

ul {
    margin: 0px;
}

.navbar {
    background: var(--white-color);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    padding: 0px !important;
}

.fixed-header {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}

/* .fixed-header .main-menu>li {
    padding: 26px 0px;
} */

.header-container {
    margin: auto;
    padding: 0 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.navbar-collapse {
    flex-grow: 0;
}

.main-menu>li {
    display: inline-block;
    padding: 38px 0px;
    margin: 0px 50px;
    color: var(--dark-blue-color);
    font-family: var(--rubik-text);
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    position: relative;
}

.main-menu li img {
    display: none;
}

.main-menu li a {
    color: var(--dark-blue-color) !important;
    padding: 0px;
}

.main-menu .active-link {
    color: var(--orange-color) !important;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show::after {
    transform: rotate(180deg);
}

.sub-menu {
    position: absolute;
    width: 320px;
    border-radius: 10px;
    background: var(--white-color);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    padding: 25px 30px;
    transform: translateX(-30%);
    transition: 0.3s;
    z-index: 11;
    margin-top: 0px !important;
}

.sub-menu li:not(:first-child) {
    margin-top: 25px;
}

.sub-menu li a {
    padding: 0px;
    color: var(--orange-color);
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.sub-menu li a:hover {
    background-color: transparent;
}

.main-menu li:hover>a {
    color: var(--orange-color) !important;
    transition: 0.3s;
}

.dropdown-toggle::after {
    content: "\f107";
    font-family: FontAwesome;
    border: none;
    vertical-align: 0px;
    font-size: 18px;
}

.navbar-toggler {
    border: none;
    outline: none;
    color: var(--dark-blue-color);
}

.navbar-toggler:focus {
    box-shadow: none;
}

.navbar-toggler i,
.close-icon {
    font-size: 27px;
    color: var(--dark-blue-color);
}
  

/* ----------------------------------------------- Media Query ----------------------------------------------- */
@media only screen and (min-width: 1440px) and (max-width: 1600px) {
    .header-container {
        width: 100%;
        padding: 0px 100px;
    }

    .header-container>div {
        align-items: center;
    }

    .header-container .main-menu {
        padding: 0px;
    }

    .header-container .main-menu>li {
        padding: 30px 0px;
        margin: 0px 30px;
        font-size: 18px;
    }

    .sub-menu {
        width: 280px;
        padding: 16px 0px;
    }

    .sub-menu li {
        padding: 8px 22px;
    }
}
@media only screen and (min-width: 1201px) and (max-width: 1440px) {
    .header-container {
        width: 100%;
        padding: 0px 100px;
    }

    .header-container>div {
        align-items: center;
    }

    .header-container .main-menu {
        padding: 0px;
    }

    .header-container .main-menu>li {
        padding: 30px 0px;
        margin: 0px 20px;
        font-size: 18px;
    }

    .sub-menu {
        width: 280px;
        padding: 16px 0px;
    }

    .sub-menu li {
        padding: 8px 22px;
    }
}

@media all and (min-width: 321px) and (max-width: 480px) {
    .navbar {
        padding: 30px 0px 21px !important;
        position: relative;
    }

    .header-container {
        padding: 0px 20px;
        width: 100%;
    }

    .header-logo img {
        width: 106px;
        height: 33px;
    }

    .main-menu {
        padding: 0px;
        display: flex;
        flex-direction: column;
        gap: 36px;
    }

    hr {
        margin: 19px 0px 30px 0px;
        opacity: 1;
        border-top: 1.5px solid #ACC8D2;
    }

    .main-menu>li {
        margin: 0px;
        padding: 0px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        display: flex;
        align-items: center;
        gap: 16.89px;
        flex-wrap: wrap;
    }

    .main-menu li img {
        display: block;
    }

    .main-menu li:nth-child(2) a {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .main-menu>li:last-child a {
        background-color: var(--orange-color);
        padding: 10px 0px;
        width: 100%;
        border-radius: 50px;
        color: var(--white-color) !important;
        font-family: var(--rubik-text);
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        text-align: center;
    }

    .dropdown-toggle::after {
        font-size: 17px;
        margin-left: 250%;
        transform: rotate(-90deg);
    }

    .navbar-nav .sub-menu {
        transform: translateX(0%);
        box-shadow: none;
        width: 100%;
        border-radius: 0px;
        padding: 0px;
        padding-top: 20px;
        border: none;
    }

    .sub-menu li:not(:nth-child(1)) {
        border-top: 1px solid #ACC8D2;
        margin-top: 0px;
        padding-top: 20px;
    }

    .sub-menu li:not(:last-child) {
        padding-bottom: 10px;
    }

    .sub-menu li a {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
    }
}

@media only screen and (min-width: 412px) and (max-width: 915px) {
    .navbar {
        padding: 30px 0px 21px !important;
        position: relative;
    }

    .header-container {
        padding: 0px 20px;
        width: 100%;
    }

    .header-logo img {
        width: 80%;
        height: 80%;
    }

    .main-menu {
        padding: 0px;
        display: flex;
        flex-direction: column;
        gap: 36px;
    }

    hr {
        margin: 19px 0px 30px 0px;
        opacity: 1;
        border-top: 1.5px solid #ACC8D2;
    }

    .navbar-toggler i,
    .close-icon {
        font-size: 35px;
    }

    .main-menu>li {
        margin: 0px;
        padding: 0px;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        display: flex;
        align-items: center;
        gap: 18px;
        flex-wrap: wrap;
    }

    .main-menu li img {
        display: block;
    }

    .main-menu li:nth-child(2) a {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .main-menu>li:last-child a {
        background-color: var(--orange-color);
        padding: 14px 0px;
        width: 100%;
        border-radius: 50px;
        color: var(--white-color) !important;
        font-family: var(--rubik-text);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        text-align: center;
    }

    .dropdown-toggle::after {
        font-size: 22px;
        margin-left: 300%;
        transform: rotate(-90deg);
    }

    .navbar-nav .sub-menu {
        transform: translateX(0%);
        box-shadow: none;
        width: 100%;
        border-radius: 0px;
        padding: 0px;
        padding-top: 20px;
        border: none;
    }

    .sub-menu li:not(:nth-child(1)) {
        border-top: 1px solid #ACC8D2;
        margin-top: 0px;
        padding-top: 20px;
    }

    .sub-menu li:not(:last-child) {
        padding-bottom: 10px;
    }

    .sub-menu li a {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .navbar {
        padding: 24px 0px !important;
    }

    /* .navbar-collapse {
        width: 100% !important;
        background-color: lightcoral;
    } */

    .header-container {
        padding: 0px 40px;
        width: 100%;
    }

    .header-logo img {
        width: 160px;
        height: 52.444px;
    }

    .main-menu>li {
        font-size: 22px;
    }

    .dropdown-toggle::after {
        font-size: 26px;
        margin-left: 480%;
    }

    .sub-menu li a {
        font-size: 17px;
    }
}