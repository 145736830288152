@import url('https://fonts.googleapis.com/css2?family=Archivo&family=Josefin+Sans:wght@600&family=Rubik&display=swap');

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px !important;
}

a {
    text-decoration: none !important;
}

:root {
    --josefin-text: 'Josefin Sans', sans-serif;
    --rubik-text: 'Rubik', sans-serif;
    --archivo-text: 'Archivo', sans-serif;
    --orange-color: #F6821F;
    --dark-blue-color: #005777;
    --white-color: #FFF;
}

/* ---------------------------------------------------- Section-1 ---------------------------------------------------- */
.contact-us-part {
    margin: 117px 0px 100px 0px;
}

.contact-us-part .contact-us-container {
    padding: 0px 150px;
}

.contact-us-part .title-part {
    color: var(--dark-blue-color);
    font-family: var(--josefin-text);
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.contact-us-part .map-section .map {
    width: 100%;
    height: 610px;
}

.contact-us-part .map-section,
.contact-us-part .contact-us-info-content {
    margin-top: 100px;
}

.contact-us-part .contact-us-info-content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;
}

.contact-us-part .address-part,
.contact-us-part .email-part,
.contact-us-part .phone-part {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 50px;
    border-radius: 5px;
    border: 1px solid var(--dark-blue-color);
    padding: 48px 34px;
}

.contact-us-part .address-part .address-icon-box,
.contact-us-part .email-part .email-icon-box,
.contact-us-part .phone-part .phone-icon-box {
    width: 79px;
    height: 79px;
    background-color: #F2F6F8;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-us-part .address-part .address-icon-box .location-icon,
.contact-us-part .email-part .email-icon-box .email-icon,
.contact-us-part .phone-part .phone-icon-box .phone-icon {
    width: 35px;
    height: 35px;
    color: var(--dark-blue-color);
}

.contact-us-part .address-part .address-info,
.contact-us-part .email-part .email-info,
.contact-us-part .phone-part .phone-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    width: 411px;
}

.contact-us-part .address-part .address-info h1,
.contact-us-part .email-part .email-info h1,
.contact-us-part .phone-part .phone-info h1 {
    color: var(--dark-blue-color);
    font-family: var(--josefin-text);
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.contact-us-part .address-part .address-info p,
.contact-us-part .email-part .email-info p,
.contact-us-part .phone-part .phone-info p {
    color: var(--dark-blue-color);
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


/* ---------------------------------------------------- Section-2 ---------------------------------------------------- */
.contact-us-form .contact-us-container {
    padding: 0px 150px;
}

.contact-us-form .title-part {
    color: var(--dark-blue-color);
    font-family: var(--josefin-text);
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 100px !important;
}

.contact-us-form input,
.contact-us-form .message {
    border-radius: 5px;
    border: 2px solid var(--dark-blue-color);
    padding: 25px 20px;
    width: 100%;
    font-family: var(--rubik-text);
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.contact-us-form input::placeholder,
.contact-us-form .message::placeholder {
    color: var(--dark-blue-color);
    font-weight: 500;
}

.contact-us-form input:focus,
.contact-us-form .message:focus {
    border: 2px solid var(--dark-blue-color);
    outline: none;
}

.contact-us-form .message {
    resize: none;
    height: 300px !important;
    padding: 22px 20px;
    margin-top: 50px;
}

.contact-us-form .submit-btn {
    border-radius: 50px;
    background-color: var(--orange-color);
    padding: 9px 80px 13px;
    color: var(--white-color);
    font-family: var(--rubik-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 170%;
    border: none;
    outline: none;
    letter-spacing: 1px;
    margin: 100px 0px;
}

/* ----------------------------------------------- Media Query ----------------------------------------------- */


@media all and (min-width: 321px) and (max-width: 480px) {

    /* ---------------------------------------------------- Section - 1 ---------------------------------------------------- */
    .contact-us-part {
        margin: 70px 0px 80px 0px;
    }

    .contact-us-part .contact-us-container,
    .contact-us-form .contact-us-container {
        padding: 0px 20px;
    }

    .contact-us-part .contact-us-container .row>.contact-col:nth-child(2) {
        order: 2;
    }

    .contact-us-part .title-part,
    .contact-us-form .title-part {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .contact-us-part .map-section {
        margin-top: 50px;
    }

    .contact-us-part .contact-us-info-content {
        margin-top: 30px;
    }

    .contact-us-part .address-part,
    .contact-us-part .email-part,
    .contact-us-part .phone-part {
        width: 100%;
        padding: 15px 20px;
        gap: 25px;
    }

    .contact-us-part .address-part .address-icon-box,
    .contact-us-part .email-part .email-icon-box,
    .contact-us-part .phone-part .phone-icon-box {
        width: 70px;
        height: 40px;
    }

    .contact-us-part .address-part .address-icon-box .location-icon,
    .contact-us-part .email-part .email-icon-box .email-icon,
    .contact-us-part .phone-part .phone-icon-box .phone-icon {
        width: 20px;
        height: 20px;
    }

    .contact-us-part .address-part .address-info,
    .contact-us-part .email-part .email-info,
    .contact-us-part .phone-part .phone-info {
        gap: 5px;
    }

    .contact-us-part .address-part .address-info h1,
    .contact-us-part .email-part .email-info h1,
    .contact-us-part .phone-part .phone-info h1 {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .contact-us-part .address-part .address-info p,
    .contact-us-part .email-part .email-info p,
    .contact-us-part .phone-part .phone-info p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    /* ---------------------------------------------------- Section - 2 ---------------------------------------------------- */
    .contact-us-form .contact-us-container .row .col-sm-12:nth-child(2),
    .contact-us-form .contact-us-container .row .col-sm-12:nth-child(3) {
        margin-bottom: 20px;
    }

    .contact-us-form .title-part {
        margin-bottom: 20px !important;
    }

    .contact-us-form input,
    .contact-us-form .message {
        padding: 20px 13px;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 10px;
    }

    .contact-us-form .message {
        margin-top: 20px;
    }

    .contact-us-form input::placeholder,
    .contact-us-form .message::placeholder {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 10px;
    }

    .contact-us-form .submit-btn {
        margin-top: 30px;
        margin-bottom: 10px;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        padding: 11px 49px;
    }
}

@media only screen and (min-width: 412px) and (max-width: 992px) {

    /* ---------------------------------------------------- Section - 1 ---------------------------------------------------- */
    .contact-us-part {
        margin: 70px 0px 80px 0px;
    }

    .contact-us-part .contact-us-container,
    .contact-us-form .contact-us-container {
        padding: 0px 20px;
    }

    .contact-us-part .contact-us-container .row>.contact-col:nth-child(2) {
        order: 2;
    }

    .contact-us-part .title-part,
    .contact-us-form .title-part {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .contact-us-part .map-section {
        margin-top: 50px;
    }

    .contact-us-part .contact-us-info-content {
        margin-top: 30px;
    }

    .contact-us-part .address-part,
    .contact-us-part .email-part,
    .contact-us-part .phone-part {
        width: 100%;
        padding: 15px 20px;
        gap: 25px;
    }

    .contact-us-part .address-part .address-icon-box,
    .contact-us-part .email-part .email-icon-box,
    .contact-us-part .phone-part .phone-icon-box {
        width: 70px;
        height: 40px;
    }

    .contact-us-part .address-part .address-icon-box .location-icon,
    .contact-us-part .email-part .email-icon-box .email-icon,
    .contact-us-part .phone-part .phone-icon-box .phone-icon {
        width: 20px;
        height: 20px;
    }

    .contact-us-part .address-part .address-info,
    .contact-us-part .email-part .email-info,
    .contact-us-part .phone-part .phone-info {
        gap: 5px;
    }

    .contact-us-part .address-part .address-info h1,
    .contact-us-part .email-part .email-info h1,
    .contact-us-part .phone-part .phone-info h1 {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .contact-us-part .address-part .address-info p,
    .contact-us-part .email-part .email-info p,
    .contact-us-part .phone-part .phone-info p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    /* ---------------------------------------------------- Section - 2 ---------------------------------------------------- */
    .contact-us-form .contact-us-container .row .col-sm-12:nth-child(2),
    .contact-us-form .contact-us-container .row .col-sm-12:nth-child(3) {
        margin-bottom: 20px;
    }

    .contact-us-form .title-part {
        margin-bottom: 20px !important;
    }

    .contact-us-form input,
    .contact-us-form .message {
        padding: 20px 13px;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 10px;
    }

    .contact-us-form .message {
        margin-top: 20px;
    }

    .contact-us-form input::placeholder,
    .contact-us-form .message::placeholder {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 10px;
    }

    .contact-us-form .submit-btn {
        margin-top: 30px;
        margin-bottom: 10px;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        padding: 11px 49px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

    /* ---------------------------------------------------- Section - 1 ---------------------------------------------------- */
    .contact-us-part {
        margin: 70px 0px;
    }

    .contact-us-part .contact-us-container,
    .contact-us-form .contact-us-container {
        padding: 0px 40px;
    }

    .contact-us-part .title-part,
    .contact-us-form .title-part {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .contact-us-part .map-section {
        margin-top: 40px;
    }

    .contact-us-part .map-section .map {
        height: 292px;
        border-radius: 10px;
    }

    .contact-us-part .contact-us-info-content {
        margin-top: 40px;
        gap: 29px;
    }

    .contact-us-part .address-part,
    .contact-us-part .email-part,
    .contact-us-part .phone-part {
        width: 100%;
        padding: 12px 11px;
        gap: 15px;
    }

    .contact-us-part .address-part .address-icon-box,
    .contact-us-part .email-part .email-icon-box,
    .contact-us-part .phone-part .phone-icon-box {
        width: 75px;
        height: 45px;
    }

    .contact-us-part .address-part .address-icon-box .location-icon,
    .contact-us-part .email-part .email-icon-box .email-icon,
    .contact-us-part .phone-part .phone-icon-box .phone-icon {
        width: 22px;
        height: 22px;
    }

    .contact-us-part .address-part .address-info,
    .contact-us-part .email-part .email-info,
    .contact-us-part .phone-part .phone-info {
        gap: 5px;
    }

    .contact-us-part .address-part .address-info h1,
    .contact-us-part .email-part .email-info h1,
    .contact-us-part .phone-part .phone-info h1 {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .contact-us-part .address-part .address-info p,
    .contact-us-part .email-part .email-info p,
    .contact-us-part .phone-part .phone-info p {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    /* ---------------------------------------------------- Section - 2 ---------------------------------------------------- */
    .contact-us-form .contact-us-container .row .col-sm-12:nth-child(2),
    .contact-us-form .contact-us-container .row .col-sm-12:nth-child(3) {
        margin-bottom: 20px;
    }

    .contact-us-form .title-part {
        margin-bottom: 30px !important;
    }

    .contact-us-form input,
    .contact-us-form .message {
        padding: 12px 15px;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 10px;
    }

    .contact-us-form .message {
        margin-top: 0px;
        padding: 20px 15px;
        height: 200px !important;
    }

    .contact-us-form input::placeholder,
    .contact-us-form .message::placeholder {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 10px;
    }

    .contact-us-form .submit-btn {
        margin-top: 30px;
        margin-bottom: 0px;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        padding: 13px 49px;
    }
}
@media only screen and (min-width: 1201px) and (max-width: 1440px) {

    /* ---------------------------------------------------- Section - 1 ---------------------------------------------------- */
    
.contact-us-part .contact-us-container {
    padding: 0px 100px !important;
}
    .contact-us-part {
        margin-top: 100px;
    }
    .contact-us-part .map-section .map {
        width: 100%;
        height: 450px;
    }

    .contact-us-part .address-part,
    .contact-us-part .email-part,
    .contact-us-part .phone-part {
        padding: 30px 20px;
        gap: 26px;
        width: 100%;
    }

    .contact-us-part .address-part .address-icon-box,
    .contact-us-part .email-part .email-icon-box,
    .contact-us-part .phone-part .phone-icon-box {
        width: 60px;
        height: 50px;
    }

    .contact-us-part .address-part .address-icon-box .location-icon,
    .contact-us-part .email-part .email-icon-box .email-icon,
    .contact-us-part .phone-part .phone-icon-box .phone-icon {
        width: 24px;
        height: 24px;
    }

    .contact-us-part .address-part .address-info h1,
    .contact-us-part .email-part .email-info h1,
    .contact-us-part .phone-part .phone-info h1 {
        font-size: 24px;
    }

    .contact-us-part .address-part .address-info p,
    .contact-us-part .email-part .email-info p,
    .contact-us-part .phone-part .phone-info p {
        font-size: 16px;
    }

    /* ---------------------------------------------------- Section - 2 ---------------------------------------------------- */
    .contact-us-form input,
    .contact-us-form .message {
        padding: 18px 14px;
    }
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {

    /* ---------------------------------------------------- Section - 1 ---------------------------------------------------- */
    
    .contact-us-part .contact-us-container {
        padding: 0px 100px !important;
    }
    .contact-us-part {
        margin-top: 100px;
    }


    .contact-us-part .map-section .map {
        width: 100%;
        height: 450px;
    }

    .contact-us-part .address-part,
    .contact-us-part .email-part,
    .contact-us-part .phone-part {
        padding: 30px 20px;
        gap: 26px;
        width: 100%;
    }

    .contact-us-part .address-part .address-icon-box,
    .contact-us-part .email-part .email-icon-box,
    .contact-us-part .phone-part .phone-icon-box {
        width: 60px;
        height: 60px;
    }

    .contact-us-part .address-part .address-icon-box .location-icon,
    .contact-us-part .email-part .email-icon-box .email-icon,
    .contact-us-part .phone-part .phone-icon-box .phone-icon {
        width: 27px;
        height: 27px;
    }

    .contact-us-part .address-part .address-info h1,
    .contact-us-part .email-part .email-info h1,
    .contact-us-part .phone-part .phone-info h1 {
        font-size: 26px;
    }

    .contact-us-part .address-part .address-info p,
    .contact-us-part .email-part .email-info p,
    .contact-us-part .phone-part .phone-info p {
        font-size: 18px;
    }

    /* ---------------------------------------------------- Section - 2 ---------------------------------------------------- */
    .contact-us-form input,
    .contact-us-form .message {
        padding: 18px 14px;
    }
}